import * as React from "react";
import {
  Box,
  Button,
  TextField,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material";
import RoomfinderImg from "../../assets/images/room-finder.jpg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Provider, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Validation } from "./validation";
import { light } from "@mui/material/styles/createPalette";
import { getTheme } from "../../styles/theme";

// CommonForm.js
export default function CommonForm({
  formValues,
  setFormValues,
  handleChange,
  availableRooms,
  setAvailableRooms,
  availableRoomsRef,
  setLoader,
  initialState,
  setSelectedRooms,
}) {
  const navigate = useNavigate();
  const [checkInError, setCheckInError] = React.useState(false);
  const [checkOutError, setCheckOutError] = React.useState(false);

  const [checkIn, setCheckIn] = [
    formValues.checkIn,
    (date) => handleChange({ target: { name: "checkIn", value: dayjs(date) } }),
  ];
  const [checkOut, setCheckOut] = [
    formValues.checkOut,
    (date) =>
      handleChange({ target: { name: "checkOut", value: dayjs(date) } }),
  ];

  const currencies = [
    {
      value: "TRY",
      label: "₺ - TRY",
    },
    {
      value: "GBP",
      label: "£ - GBP",
    },
    {
      value: "CNY",
      label: "¥ - CNY",
    },
  ];

  const [providers, setProviders] = React.useState([]);
  const [campuses, setCampuses] = React.useState([]);
  const [courses, setCourses] = React.useState([]);

  async function getProviders(url, data) {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      // URLSearchParams ile POST parametreleri oluşturuluyor
      const params = new URLSearchParams();
      // params.append("api_key", "O0AUQ0KLAD3I71K8YKR82Y6AP");
      // params.append("environment", process.env.REACT_APP_PUBLIC_ENVIRONMENT);

      let url = `${process.env.REACT_APP_BASE_URL}booking/providers`;
      // Axios ile POST isteği yapılıyor
      const response = await axios.post(url, params, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/x-www-form-urlencoded", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
        // headers: {
        //   "Content-Type": "application/x-www-form-urlencoded", // Form verisi olarak gönderiliyor
        // },
      });

      // İstek başarılı olduğunda sunucudan dönen yanıtı göster
      setProviders(response.data.result);
      // setFormValues((prev) => ({
      //   ...prev,
      //   // provider: response.data.result.Providers.find((res) => {
      //   //   return res.partner_id == 2;
      //   // }),
      //   // checkIn: dayjs("2024-02-20"),
      //   // checkOut: dayjs("2024-02-28"),
      // }));
    } catch (error) {
      // Hata durumunda hata mesajını göster
      console.error("Error during the request:", error.message);
    }
  }

  async function getCampuses(url, apiKey, partnerId) {
    try {
      // URLSearchParams ile POST parametreleri oluşturuluyor
      const params = new URLSearchParams();
      params.append("partner_id", `${partnerId}`);
      // params.append("api_key", "O0AUQ0KLAD3I71K8YKR82Y6AP");
      // params.append("environment", process.env.REACT_APP_PUBLIC_ENVIRONMENT);
      let accessToken = localStorage.getItem("accessToken");
      let token = localStorage.getItem("loginUser");
      let url = `${process.env.REACT_APP_BASE_URL}booking/campuses`;

      // Axios ile POST isteği yapılıyor
      const response = await axios.post(url, params, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/x-www-form-urlencoded", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
        // headers: {
        //   "Content-Type": "application/x-www-form-urlencoded", // Form verisi olarak gönderiliyor
        // },
      });

      // İstek başarılı olduğunda sunucudan dönen yanıtı göster
      setCampuses(response.data.result);
    } catch (error) {
      // Hata durumunda hata mesajını göster
      console.error("Error during the request:", error.message);
    }
  }

  async function getCourses(url, apiKey, campusId) {
    try {
      // URLSearchParams ile POST parametreleri oluşturuluyor
      const params = new URLSearchParams();
      params.append("campus_id", `${campusId}`);
      // params.append("api_key", "O0AUQ0KLAD3I71K8YKR82Y6AP");
      // params.append("environment", process.env.REACT_APP_PUBLIC_ENVIRONMENT);

      let accessToken = localStorage.getItem("accessToken");
      let token = localStorage.getItem("loginUser");
      let url = `${process.env.REACT_APP_BASE_URL}booking/room_types`;

      // Axios ile POST isteği yapılıyor
      const response = await axios.post(url, params, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/x-www-form-urlencoded", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
        // headers: {
        //   "Content-Type": "application/x-www-form-urlencoded", // Form verisi olarak gönderiliyor
        // },
      });

      // İstek başarılı olduğunda sunucudan dönen yanıtı göster
      setCourses(response.data.result);
    } catch (error) {
      // Hata durumunda hata mesajını göster
      console.error("Error during the request:", error.message);
    }
  }

  React.useEffect(() => {
    let url = process.env.WEBSERVICE_LONDONIST;
    let apiKey = process.env.WEBSERVICE_LONDONIST_APIKEY;
    getProviders(url, apiKey);

    if (formValues.refreshControl) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      let newFormValues = { ...formValues }; // Mevcut form değerlerinin bir kopyasını oluşturun

      for (let key in formValues) {
        if (urlParams.has(key)) {
          if (key === "checkIn" || key === "checkOut") {
            // checkIn ve checkOut için yeni değerleri hazırlayın
            newFormValues[key] = dayjs(urlParams.get(key));
          }
        }
      }

      // Tek bir setFormValues çağrısı ile tüm değişiklikleri uygulayın
      setFormValues(newFormValues);
    }
  }, []);

  React.useEffect(() => {
    if (providers.length) {
      // URL'deki sorgu dizesini almak için
      const queryString = window.location.search;

      // URLSearchParams objesini kullanarak sorgu dizesini ayrıştırın
      const urlParams = new URLSearchParams(queryString);

      if (urlParams.size) {
        // formValues objesindeki her anahtarı kontrol edin
        let findedProvider = providers.find((provider) => {
          return (
            provider.partner_id === parseInt(urlParams.get("provider"), 10)
          );
        });
        setFormValues((prev) => ({
          ...prev,
          provider: findedProvider,
        }));
      }

      for (let key in formValues) {
        if (urlParams.has(key)) {
          // Eğer anahtar URL parametrelerinde varsa

          if (key === "provider") {
          }
        } else {
          // Eğer anahtar URL parametrelerinde yoksa
        }
      }
    }
  }, [providers]);

  React.useEffect(() => {
    const queryString = window.location.search;

    // URLSearchParams objesini kullanarak sorgu dizesini ayrıştırın
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.size) {
      // formValues objesindeki her anahtarı kontrol edin
      let findedCampus = campuses.find((campus) => {
        return campus.campus_id === parseInt(urlParams.get("campus"), 10);
      });
      if (findedCampus) {
        setFormValues((prev) => ({
          ...prev,
          campus: findedCampus,
        }));
      }
    }
  }, [campuses]);

  React.useEffect(() => {
    const queryString = window.location.search;

    // URLSearchParams objesini kullanarak sorgu dizesini ayrıştırın
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.size) {
      // formValues objesindeki her anahtarı kontrol edin
      let findedCourse = courses.find((course) => {
        return course.course_id === parseInt(urlParams.get("course"), 10);
      });

      console.log(findedCourse, "finded course here 6161");
      if (findedCourse) {
        console.log(findedCourse, "finded course here 3434");
        setFormValues((prev) => ({
          ...prev,
          course: findedCourse,
        }));
      }
    }
  }, [courses]);

  React.useEffect(() => {
    if (formValues.provider) {
      let url = process.env.WEBSERVICE_LONDONIST;
      let apiKey = process.env.WEBSERVICE_LONDONIST_APIKEY;

      let partnerId = formValues.provider.partner_id;
      getCampuses(url, apiKey, partnerId);

      if (!formValues.refreshControl) {
        setFormValues((prev) => ({
          ...prev,
          campus: "",
          course: "",
        }));
      }
      setCourses([]);
      // if (!formValues.campus) {
      //   handleFindRooms();
      // }
    }
  }, [formValues.provider]);

  React.useEffect(() => {
    if (formValues.campus) {
      let url = process.env.WEBSERVICE_LONDONIST;
      let apiKey = process.env.WEBSERVICE_LONDONIST_APIKEY;
      // let campusId;
      // if (formValues.campus.length) {
      //   let campusIds = [];
      //   formValues.campus.forEach((camp) => {
      //     campusIds.push(camp.campus_id);
      //   });
      //   campusId = campusIds;
      // } else {
      //   campusId = formValues.campus.campus_id;
      // }
      let campusId = formValues.campus.campus_id;

      getCourses(url, apiKey, campusId);
      if (!formValues.refreshControl) {
        setFormValues((prev) => ({
          ...prev,
          course: "",
        }));
      }
      setCourses([]);
    }
  }, [formValues.campus]);

  const handleFindRooms = async () => {
    if (
      formValues.checkIn &&
      formValues.checkOut &&
      formValues.courses !== "All Room Types"
    ) {
      let queryParts = [];
      const excludeFromUrl = [
        "currency",
        "numberOfStudents",
        "occupancy",
        "additionalNote",
      ];

      for (const [key, value] of Object.entries(formValues)) {
        // if (value && value !== "") {
        if (value && value !== "" && !excludeFromUrl.includes(key)) {
          if (key === "checkIn" || key === "checkOut") {
            // Eğer checkIn veya checkOut değerlerini işliyorsak, bu değerleri formatlamamız gerekiyor.
            queryParts.push(`${key}=${value.format("YYYY-MM-DD")}`);
          } else if (key === "provider") {
            queryParts.push(`${key}=${value.partner_id}`);
          } else if (key === "campus") {
            queryParts.push(`${key}=${value.campus_id}`);
          } else if (key === "course") {
            if (value !== "All Room Types") {
              queryParts.push(`${key}=${value.course_id}`);
            }
          } else {
            queryParts.push(`${key}=${value}`);
          }
        }
        // }
      }

      const queryString = queryParts.join("&");
      navigate(`/room-finder?${queryString}`);
      setLoader(true)
      try {
        // URLSearchParams ile POST parametreleri oluşturuluyor
        const params = new URLSearchParams();
        // params.append("api_key", "O0AUQ0KLAD3I71K8YKR82Y6AP");
        // params.append("environment", process.env.REACT_APP_PUBLIC_ENVIRONMENT);

        if (formValues.provider) {
          params.append("provider", formValues.provider.partner_id);
        }
        if (formValues.campus) {
          params.append("residences", formValues.campus.campus_id);
        }
        if (formValues.course) {
          if (
            !formValues.course.length &&
            formValues.course !== "All Room Types"
          ) {
            params.append("room_type", formValues.course.course_id);
          }
        }
        if (formValues.checkIn) {
          params.append("start_date", formValues.checkIn.format("YYYY-MM-DD"));
        }
        if (formValues.checkOut) {
          params.append("end_date", formValues.checkOut.format("YYYY-MM-DD"));
        }

        let accessToken = localStorage.getItem("accessToken");
        let token = localStorage.getItem("loginUser");
        let url = `${process.env.REACT_APP_BASE_URL}booking/rooms`;

        // Axios ile POST isteği yapılıyor
        const response = await axios.post(url, params, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            "Content-Type": "application/x-www-form-urlencoded", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
          // headers: {
          //   "Content-Type": "application/x-www-form-urlencoded", // Form verisi olarak gönderiliyor
          // },
        });

        const filteredAvailableRooms = response?.data?.result.reduce(
          (acc, current) => {
            // Benzersiz anahtar oluşturma
            const uniqueKey = `${current.partner_name}_${current.campus_name}_${current.course_name}`;
            // Eğer bu anahtar daha önce eklenmemişse, yeni bir grup oluştur ve count'u 1 yap
            if (!acc.temp[uniqueKey]) {
              acc.temp[uniqueKey] = {
                ...current, // Mevcut oda bilgilerini al
                count: 1, // Bu gruptaki oda sayısını başlat
              };
            } else {
              // Eğer bu anahtar varsa, count'u artır
              acc.temp[uniqueKey].count += 1;
            }
            return acc;
          },
          { temp: {} }
        ).temp; // Reduce işlemi sonucunda temp objesini al

        // Sonuç olarak elde edilen objeyi diziye çevir
        const resultArray = Object.values(filteredAvailableRooms);

        // Bu dizi, her bir benzersiz oda grubu için bir obje içerir ve her objenin bir 'count' özelliği vardır.

        // Benzersiz objeleri setAvailableRooms ile kaydet

        // response'dan gelen veriyi işleme
        const availableRooms = response?.data?.result; // varsayılan olarak response'dan gelen veri

        // Öğelerin tekrar sayısını tutacak bir nesne oluştur
        const itemCounts = {};

        // Her öğe için tekrar sayısını hesaplama
        availableRooms.forEach((room) => {
          const uniqueKey = `${room.partner_name}_${room.campus_name}_${room.course_name}`;
          if (itemCounts[uniqueKey]) {
            itemCounts[uniqueKey].count++;
            itemCounts[uniqueKey].items.push(room);
          } else {
            itemCounts[uniqueKey] = { count: 1, items: [room] };
          }
        });

        // Tekrar eden öğeleri içeren dizileri tutacak bir dizi oluştur
        const repeatedItemsGroups = Object.values(itemCounts)
          .filter((item) => item.count > 1)
          .map((item) => item.items);

        // Tekrar eden her grup için konsola yazdır
        // repeatedItemsGroups.forEach((group, index) => {
        //   console.log(`Group ${index + 1}:`, group);
        // });

        setAvailableRooms(resultArray);

        setLoader(false)
        // if (!response?.data?.result.length) {
        //   setLoader(true);
        // } else {
        //   setLoader(false);
        // }
      } catch (error) {
        // Hata durumunda hata mesajını göster
        console.error("Error during the request:", error.message);
      }
    } else {
      Validation(
        formValues,
        checkInError,
        setCheckInError,
        checkOutError,
        setCheckOutError
      );
    }
  };

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (
      urlParams.size &&
      Object.keys(formValues).length &&
      formValues.refreshControl
    ) {
      let filledFormKeys = [];

      for (let key in formValues) {
        if (
          key !== "refreshControl" &&
          formValues[key] !== null &&
          formValues[key] !== undefined &&
          formValues[key] !== ""
        ) {
          filledFormKeys.push(key);
        }
      }

      // `refreshControl` parametresini URLSearchParams objesinden çıkar
      if (urlParams.has("refreshControl")) {
        urlParams.delete("refreshControl");
      }

      if (!availableRooms?.length) {
        // Burada, sorgu parametrelerinin sayısını kontrol ediyoruz
        if (Array.from(urlParams.keys()).length === filledFormKeys.length) {
          handleFindRooms();
        }
      }
    } else {
      Validation(
        formValues,
        checkInError,
        setCheckInError,
        checkOutError,
        setCheckOutError
      );
    }
  }, [formValues]);

  // React.useEffect(() => {
  //   console.log(checkInError, "check in error");
  // }, [checkInError]);

  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const theme = createTheme({
    palette: {
      mode: lightMode ? "light" : "dark", // Modu Redux state'ine bağlayın
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: lightMode ? "#bf0000" : "#ffffff",
            color: lightMode ? "#ffffff" : "#000000",
            "&:hover": {
              backgroundColor: lightMode ? "#bf0000" : "#ffffff",
              color: lightMode ? "#ffffff" : "#000000",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: lightMode && "#1d1b1a !important",
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "#bf000038",
            },
            "&:not(.Mui-selected)": {
              // borderColor: "#bf000038 !important",
            },
            "&.Mui-selected": {
              backgroundColor: "#bf0000 !important",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#800000 !important",
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: !lightMode ? "#ffffff" : "#444444 !important",
          },
          input: {
            color: !lightMode && "#ffffff",
          },
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: !lightMode && "white", // Burada istediğiniz renge ayarlayabilirsiniz
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: !lightMode && "white", // Burada istediğiniz renge ayarlayabilirsiniz
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: !lightMode && "#ffffff",
            "&.MuiInputLabel-root.Mui-focused": {
              color: !lightMode ? "#ffffff !important" : "#444444 !important",
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "&.check-in": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: checkInError && "red",
              },
              ".Mui-focused": {
                color: checkInError && "red",
              },
            },
            "&.check-out": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: checkOutError && "red",
              },
              ".Mui-focused": {
                color: checkOutError && "red",
              },
            },
          },
        },
      },
    },
  });

  const disablePastDates = (date) => {
    // Örnek olarak, bugünden önceki tarihleri devre dışı bırak
    return date.isBefore(dayjs(), "2024-02-28");
  };

  const disableWeekdays = (date) => {
    // 6: Cumartesi, 0: Pazar
    const day = dayjs(date).day();
    return day !== 0 && day !== 6;
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: { xs: "auto", lg: "calc(100vh - 370px)" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            //backgroundImage: `url(${RoomfinderImg})`,
            width: "100%",
            height: "auto",
            minHeight: "calc(100vh - 470px)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            zIndex: 0,
            overflow: "hidden",
            "&:before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "100%",
              // background: `
              // linear-gradient(180deg, rgb(0 0 0), rgb(0 0 0 / 16%) 70.71%),linear-gradient(90deg, rgb(255 255 255 / 54%), rgb(255 255 255 / 17%) 70.71%),linear-gradient(270deg, rgb(0 0 0), rgb(255 255 255 / 17%) 70.71%)
              // `,
              backgroundImage: `url(${RoomfinderImg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              filter: !lightMode && "blur(5px)",
              zIndex: 1,
            },
          }}
        >
          <Box
            sx={{
              zIndex: 2,
              backgroundColor: lightMode
                ? {
                    xs: "rgba(247, 247, 247, 0.9)",
                    md: "rgba(247, 247, 247, 0.8)",
                  }
                : "rgb(0 0 0 / 80%)",
              maxWidth: "1330px",
              width: "100%",
              margin: { xs: "0", md: "5rem" },
              padding: { xs: "1rem", md: "3rem" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{ alignItems: "center", width: "100%" }}
                components={["DatePicker", "DatePicker"]}
              >
                <FormControl
                  fullWidth
                  sx={{
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Provider
                  </InputLabel>
                  <Select
                    name="provider"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formValues.provider}
                    label="provider"
                    onChange={handleChange}
                  >
                    {providers?.map((provider, idx) => {
                      return (
                        <MenuItem key={provider.partner_id} value={provider}>
                          {provider.partner_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl
                  fullWidth
                  disabled={!formValues.provider}
                  sx={{
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Campuses
                  </InputLabel>
                  <Select
                    name="campus"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formValues.campus}
                    label="Campuses"
                    onChange={handleChange}
                  >
                    {campuses?.map((campus, idx) => {
                      return (
                        <MenuItem key={campus.campus_id} value={campus}>
                          {campus.campus_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl
                  fullWidth
                  disabled={!formValues.campus}
                  sx={{
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Room Types
                  </InputLabel>
                  <Select
                    name="course"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formValues.course}
                    label="Room Types"
                    onChange={handleChange}
                  >
                    <MenuItem value={courses?.length ? "All Room Types" : null}>
                      {"All Room Types"}
                    </MenuItem>
                    {courses?.map((course, idx) => {
                      return (
                        <MenuItem key={course.course_id} value={course}>
                          {course.course_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <DatePicker
                  className="check-in"
                  sx={{
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                    width: "100%",
                    // ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    //   borderColor: checkInError
                    //     ? "red !important"
                    //     : "rgba(0, 0, 0, 0.23)", // Hata durumuna göre border rengi
                    // },
                    // ".css-oxhcol-MuiFormLabel-root-MuiInputLabel-root": {
                    //   color: checkInError ? "red !important" : "#1f1e1d", // Hata durumuna göre border rengi
                    // },
                    // ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                    //   color: checkInError
                    //     ? "red !important"
                    //     : "rgba(0, 0, 0, 0.54)", // Hata durumuna göre border rengi
                    // },
                    // ".css-1mway2v-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
                    //   {
                    //     color: checkInError ? "red !important" : "#1f1e1d", // Hata durumuna göre border rengi
                    //   },
                    // ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    //   color: checkInError ? "red !important" : "#1f1e1d", // Hata durumuna göre border rengi
                    // },
                  }}
                  label="Check-in"
                  value={checkIn}
                  onChange={(newValue) => setCheckIn(newValue)}
                  error={checkInError}
                  minDate={dayjs()} // Bugünün tarihi, geçmiş tarihler seçilemez
                  maxDate={formValues?.checkOut || null}
                  shouldDisableDate={disableWeekdays} // Haftasonları dışında tarihler devre dışı bırakılır
                />
                <DatePicker
                  className="check-out"
                  sx={{
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                    width: "100%",
                    // ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    //   borderColor: checkOutError
                    //     ? "red !important"
                    //     : "rgba(0, 0, 0, 0.23)", // Hata durumuna göre border rengi
                    // },
                    // ".css-oxhcol-MuiFormLabel-root-MuiInputLabel-root": {
                    //   color: checkOutError ? "red !important" : "#1f1e1d", // Hata durumuna göre border rengi
                    // },
                    // ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                    //   color: checkOutError
                    //     ? "red !important"
                    //     : "rgba(0, 0, 0, 0.54)", // Hata durumuna göre border rengi
                    // },
                    // ".css-1mway2v-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
                    //   {
                    //     color: checkOutError ? "red !important" : "#1f1e1d", // Hata durumuna göre border rengi
                    //   },
                    // ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    //   color: checkOutError ? "red !important" : "#1f1e1d", // Hata durumuna göre border rengi
                    // },
                  }}
                  label="Check-out"
                  error={checkOutError}
                  value={checkOut}
                  onChange={(newValue) => setCheckOut(newValue)}
                  minDate={formValues?.checkIn || null}
                  shouldDisableDate={disableWeekdays} // Haftasonları dışında tarihler devre dışı bırakılır
                />
              </DemoContainer>
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{ alignItems: "center", width: "100%", marginTop: "24px" }}
                components={["DatePicker", "DatePicker"]}
              >
                {/* <TextField
                  name="currency"
                  id="outlined-select-currency"
                  select
                  label="Currency"
                  value={formValues.currency} // formValues'dan gelen currency değerini kullanın
                  onChange={handleChange}
                  sx={{
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                    width: "100%",
                    minWidth: "unset !important",
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField> */}

                <TextField
                  sx={{
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                    width: "100%",
                    minWidth: "unset !important",
                  }}
                  onChange={handleChange}
                  name="numberOfStudents"
                  id="numberOfStudents"
                  value={formValues.numberOfStudents} // formValues'dan gelen currency değerini kullanın
                  label="Number of Students"
                  variant="outlined"
                  type="number"
                />

                <FormControl
                  fullWidth
                  sx={{
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Occupancy
                  </InputLabel>
                  <Select
                    name="occupancy"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formValues.occupancy}
                    label="occupancy"
                    onChange={handleChange}
                  >
                    <MenuItem value={"single"}>Single</MenuItem>
                    <MenuItem value={"dual"}>Dual</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  sx={{
                    marginLeft: "1rem",
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                    width: "100%",
                    minWidth: "unset !important",
                  }}
                  onChange={handleChange}
                  name="additionalNote"
                  value={formValues.additionalNote} // formValues'dan gelen currency değerini kullanın
                  id="outlined-basic"
                  label="Additional Note"
                  variant="outlined"
                  type="text"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => (
                    setFormValues(initialState),
                    setAvailableRooms([]),
                    setSelectedRooms([]),
                    navigate("/room-finder")
                  )}
                  sx={{
                    height: "100%",
                    display: "block",
                    minHeight: "56px",
                    marginLeft: "1rem",
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                    width: "100%",
                  }}
                >
                  Clear Filters
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFindRooms}
                  disabled={!formValues.checkIn || !formValues.checkOut}
                  sx={{
                    height: "100%",
                    display: "block",
                    minHeight: "56px",
                    marginLeft: "1rem",
                    maxWidth: { xs: "100%", lg: "calc((100% - 64px) / 5)" },
                    minWidth: "unset !important",
                    width: "100%",
                  }}
                >
                  FIND ROOMS
                </Button>
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
