import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Box, Card, CardMedia } from "@mui/material";

import KayakDesktopSm from "../../../assets/images/slider/dashboard/kayak_mobile.png";
import KayakDesktop from "../../../assets/images/slider/dashboard/kayak-mobile.png";
import FiftyMobile from "../../../assets/images/slider/dashboard/fifty_mobile.jpg";
import FiftyDesktop from "../../../assets/images/slider/dashboard/fifty_desktop.jpg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function App() {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Swiper
        navigation={true}
        // pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            <img
              src={window?.innerWidth < 768 ? FiftyMobile : FiftyDesktop}
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Link
            target="_blank"
            style={{ width: "100%", height: "100%" }}
            to="https://www.kayak.co.uk/?utm_source=tradetracker&utm_medium=affiliate&utm_campaign=472424&utm_content=32925"
          >
            {/* <Box
              sx={{
                backgroundImage: `url(${KayakDesktop})`,
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
                backgroundPositionX: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: ".5rem",
              }}
            ></Box> */}
            <img
              src={window?.innerWidth < 768 ? KayakDesktopSm : KayakDesktop}
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ background: lightMode ? "#ffffff" : "#343434" }}>
          <Card
            sx={{
              height: { xs: "100%", lg: "100%" },
              width: "100%",
              borderRadius: ".5rem",
            }}
          >
            <CardMedia
              component="iframe"
              style={{ borderRadius: ".5rem" }}
              height="100%" // İhtiyacınıza göre ayarlayın
              image="https://www.youtube.com/embed/t_jmqnFsKkw?si=IOpd7I-jqlscYcTT" // YOUR_VIDEO_ID yerine YouTube video ID'nizi girin
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              title="Embedded YouTube Video"
            />
            {/* <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Video Title
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Video description can be found here{" "}
                  </Typography>
                </CardContent> */}
          </Card>
        </SwiperSlide>
      </Swiper>
    </Box>
  );
}
