import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import PreviewIcon from "@mui/icons-material/Preview";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axiosInstance from "../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import SendEmailModal from "../../Modal/Quotes/QuoteDetails/SendEmailModal";
import { openModal } from "../../../redux/actions/actions";

export default function QuoteDetilsComp({
  quoteDetails,
  getQuoteDetails,
  students,
  setStudents,
}) {
  const [formValues, setFormValues] = useState();
  const [coursesControl, setCoursesControl] = useState(false);
  const [loader, setLoader] = useState(false);
  const lightMode = useSelector((state) => state.mode.lightMode);
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (eventOrValue, name) => {
    if (dayjs.isDayjs(eventOrValue)) {
      // Eğer eventOrValue bir dayjs nesnesi ise (DatePicker'dan gelme durumu)
      setFormValues((prev) => ({
        ...prev,
        [name]: dayjs(eventOrValue).format("YYYY-MM-DD"), // newValue direkt olarak kullanılıyor ve formatlanıyor
      }));
    } else {
      const { name, value } = eventOrValue.target;
      // Diğer inputlar için standart işleme
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [selectedStudent, setSelectedStudent] = useState();

  const handleChangeStudent = (_event, newValue) => {
    setSelectedStudent(newValue);
  };

  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  const handleChangeCourseControl = () => {
    setCoursesControl(!coursesControl);
  };

  useEffect(() => {
    console.log(formValues, "form values here 6161");
    if (formValues) {
      if (!formValues?.expiry_date) {
        setFormValues((prev) => ({
          ...prev,
          expiry_date:
            quoteDetails?.quote?.expiry_date || dayjs().format("YYYY-MM-DD"), // newValue direkt olarak kullanılıyor ve formatlanıyor
        }));
      }
    }
  }, [formValues]);

  useEffect(() => {
    if (selectedStudent) {
      setFormValues((prev) => ({
        ...prev,
        student: selectedStudent,
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        student: null,
      }));
    }
    console.log(selectedStudent, "sel stu");
  }, [selectedStudent]);
  useEffect(() => {
    console.log(formValues, "form values here");
    console.log(quoteDetails, "quote details values here");
    //!formValues?.options
    // let options = [];
    // quoteDetails?.options?.forEach((option, idx) => {
    //   // Yeni bir option objesi oluşturuluyor.
    //   let optionObj = {
    //     name: `Option ${idx + 1}`,
    //     accommodations: [
    //       {
    //         courses: [],
    //         services: [],
    //       },
    //     ],
    //   };

    //   // Bu sefer, her option için tüm courses üzerinde dönülüyor.
    //   option?.courses?.forEach((course, courseIndex) => {
    //     optionObj.accommodations[0].courses.push({
    //       course_id: course?.option_course_id,
    //       campus_id: course?.course?.campus?.campus_id,
    //       option_course_name: course?.option_course_name,
    //       option_course_partner: course?.option_course_partner,
    //       option_course_campus: course?.option_course_campus,
    //       option_course_start_date: dayjs(formValues?.checkIn)?.format(
    //         "YYYY-MM-DD"
    //       ),
    //       option_course_end_date: dayjs(formValues?.checkOut)?.format(
    //         "YYYY-MM-DD"
    //       ),
    //       option_course_course_type: course?.type_name,
    //       promotions: course?.promotion,
    //     });
    //   });

    //   option?.services?.forEach((service, serviceIndex) => {
    //     optionObj.accommodations[0].services.push({
    //       new_service_id: service?.new_service?.service_id,
    //       option_service_name: service?.new_service?.service_name,
    //       option_service_quantity: service?.option_service_quantity || null,
    //     });
    //   });

    //   option?.promotions?.forEach((promotion, promotionIndex) => {
    //     optionObj.accommodations[0].promotions.push({
    //       promotion_id: promotion?.option?.promotion_id || null,
    //     });
    //   });

    //   // Oluşturulan option objesi options listesine ekleniyor.
    //   options.push(optionObj);
    // });

    // console.log(options, "options here 6161");

    setFormValues((prev) => ({
      ...prev,
      quote_id: quoteDetails?.quote?.quote_id,
      // options: options,
    }));
  }, [quoteDetails]);

  const handleUpdateQuote = async () => {
    setLoader(true);
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    let url = `${process.env.REACT_APP_BASE_URL}quotes`;

    // Önce formValues'dan 'price' değerlerini kaldır
    // const modifiedFormValues = {
    //   ...formValues,
    //   options: formValues.options.map((option) => ({
    //     ...option,
    //     accommodations: option.accommodations.map((accommodation) => ({
    //       ...accommodation,
    //       courses: accommodation.courses.map((course) => {
    //         const { price, campus_address, ...restOfCourse } = course; // 'price' değerini çıkart
    //         return restOfCourse;
    //       }),
    //     })),
    //   })),
    // };

    const modifiedFormValues = {
      ...formValues,
      student_id: formValues?.student?.student_id,
    };

    try {
      const response = await axiosInstance.put(url, modifiedFormValues, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      console.log(response.data, "Quote Eklendi!");
      setLoader(false);
      Swal.fire({
        icon: "success",
        title: "Quote Updated Successfully!",
        showConfirmButton: false,
        showCloseButton: true,
      });
      navigate(`/quotes/${modifiedFormValues.quote_id}/details`);
      getQuoteDetails();
    } catch (error) {
      setLoader(false);
      console.error("Quote Eklenirken Hata oluştu!", error);
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.detail,
        showConfirmButton: false,
        showCloseButton: true,
      });
      return [];
    }
  };

  useEffect(() => {
    if (quoteDetails?.quote?.student?.student_id && students?.length > 0) {
      const matchedStudent = students.find(
        (student) =>
          student.student_id === quoteDetails.quote.student.student_id
      );
      setSelectedStudent(matchedStudent);
    }
  }, [quoteDetails, students]);

  const handleSendWhatsapp = async () => {
    setLoader(true);
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    let url = `${process.env.REACT_APP_BASE_URL}whatsapp_quote`;

    const modifiedFormValues = {
      quote_id: quoteDetails?.quote?.quote_id,
    };

    try {
      const response = await axiosInstance.post(url, modifiedFormValues, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      setLoader(false);
      if (window.innerWidth < 768) {
        window.location.href = response?.data?.messag;
      } else {
        window.open(response?.data?.message, "_blank");
      }

      // Swal.fire({
      //   icon: "success",
      //   title: "Quote Updated Successfully!",
      //   showConfirmButton: false,
      //   showCloseButton: true,
      // });
      // navigate(`/quotes/${response?.data?.quote_id}/details`);
    } catch (error) {
      setLoader(false);
      console.error("Quote Eklenirken Hata oluştu!", error);
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.detail,
        showConfirmButton: false,
        showCloseButton: true,
      });
      return [];
    }
  };

  return (
    <>
      {loader ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      ) : null}
      <Box sx={{ marginTop: { xs: "2rem" } }}>
        <Box>
          <Box
            sx={{
              marginBottom: "2rem",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: { xs: "100%", sm: "fit-content" },
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Button
                href={quoteDetails?.quote?.preview}
                target="_blank"
                variant="contained"
              >
                {" "}
                <PreviewIcon sx={{ color: "#ffffff" }} /> Preview
              </Button>
              <Button
                onClick={() =>
                  dispatch(
                    openModal(<SendEmailModal quoteDetails={quoteDetails} />)
                  )
                }
                sx={{ margin: { xs: "1rem 0", sm: "0 1rem" } }}
                variant="contained"
              >
                {" "}
                <EmailIcon sx={{ color: "#ffffff" }} /> Send to Student
              </Button>
              <Button variant="contained" onClick={handleSendWhatsapp}>
                {" "}
                <PhoneAndroidIcon sx={{ color: "#ffffff" }} /> Send with
                Whatsapp
              </Button>
            </Box>
            <Typography
              sx={{ fontWeight: "700", marginTop: { xs: "1rem", sm: "0" } }}
            >
              Quote ID: #{quoteDetails?.quote?.quote_id}
            </Typography>
          </Box>
          {/* <Box
            sx={{ boxShadow: "0px 0px 5px #cecece", padding: "1.5rem 1rem" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={quoteDetails?.quote?.student_name}
                  id="outlined-basic"
                  label="Student Name"
                  name="student_name"
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    sx={{
                      width: "100%",
                    }}
                    label="Issue Date"
                    value={dayjs(quoteDetails?.quote?.issue_date)}
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    sx={{
                      width: "100%",
                    }}
                    label="Accepted At"
                    value={dayjs(quoteDetails?.quote?.accepted_at)}
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={handleChange}
                  // disabled={loader}
                  disabled
                  fullWidth
                  value={quoteDetails?.quote?.quote_status
                    ?.split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                  id="outlined-basic"
                  label="Quote Status"
                  name="quote_status"
                  variant="outlined"
                  type="text"
                />
              </Grid>
            </Grid>
          </Box> */}

          <Box
            sx={{
              boxShadow: "0px 0px 5px #cecece",
              padding: "1.5rem 1rem",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    value={
                      selectedStudent
                        ? selectedStudent
                        : formValues?.student
                        ? formValues?.student
                        : null
                    }
                    onChange={handleChangeStudent}
                    disablePortal
                    id="combo-box-demo"
                    options={students || []}
                    disabled={!students?.length}
                    getOptionLabel={(option) => option.student_name} // 'name' özelliğini label olarak kullan
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Student"
                        placeholder="Select Student"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="Expiry Date"
                    name="expiry_date"
                    value={
                      (quoteDetails &&
                        quoteDetails?.quote &&
                        quoteDetails?.quote?.expiry_date &&
                        dayjs(quoteDetails?.quote?.expiry_date)) ||
                      dayjs(formValues?.expiry_date)
                    }
                    onChange={(newValue) =>
                      handleChange(newValue, "expiry_date")
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Button
                  sx={{
                    marginTop: "1rem",
                  }}
                  disabled={
                    !formValues?.expiry_date || !formValues?.student?.student_id
                  }
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    formValues?.expiry_date &&
                    formValues?.student?.student_id &&
                    handleUpdateQuote()
                  }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            {/* <Button
              sx={{
                marginTop: "1rem",
              }}
              disabled={!formValues?.expiry_date || !formValues?.student_id}
              variant="contained"
              color="primary"
              onClick={() =>
                formValues?.expiry_date &&
                formValues?.student_id 
                && handleUpdateQuote()
              }
            >
              Save
            </Button> */}
          </Box>
          <>
            {/* {options?.map((option, idx) => {
              return (
                <Box
                  sx={{
                    marginTop: "2rem",
                    boxShadow: "0px 0px 5px #cecece",
                    padding: "1.5rem 1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #efefef",
                      paddingBottom: "1rem",
                    }}
                  >
                    <FormatListBulletedIcon sx={{ marginRight: ".5rem" }} />
                    <Typography sx={{ fontWeight: "700" }}>
                      {option.option_name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      marginTop: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        overflow: "hidden",
                        width: { xs: "100%", md: "300px", lg: "400px" },
                        marginRight: { xs: "0", md: "2rem" },
                        marginBottom: { xs: "1.5rem", md: "0" },
                      }}
                    >
                      <Box
                        sx={{
                          backgroundImage: `url(${option.provider_img})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "left",
                          width: { xs: "100%", md: "400px" },
                          height: { xs: "300px", md: "100%" },
                          filter: "blur(8px)",
                        }}
                      ></Box>
                      <img
                        src={option.provider_img}
                        style={{
                          position: "absolute",
                          inset: "0",
                          width: "90%",
                          left: "5%",
                          borderRadius: "1rem",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: {
                          xs: "100%",
                          md: "calc(100% - 332px)",
                          lg: "calc(100% - 432px)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: "1px solid #efefef",
                          paddingBottom: "1rem",
                        }}
                      >
                        <Typography color="primary" variant="h6">
                          {option.provider_name}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <LocationOnIcon
                            sx={{ fontSize: "14px", color: "#bf0000" }}
                          />
                          <Typography variant="body2">
                            {option.provider_address}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            color="primary"
                            variant="body2"
                            sx={{ marginRight: ".5rem" }}
                          >
                            Academic Year:
                          </Typography>
                          <Typography variant="body2">
                            {option.academic_year}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "1rem",
                          borderBottom: "1px solid #efefef",
                          paddingBottom: "1rem",
                        }}
                      >
                        <Typography color="primary" variant="h6">
                          Accommodation/s
                        </Typography>
                        <Typography variant="body2">
                          {option.campus_name}
                        </Typography>
                        <Typography variant="body2">
                          {option.duration}
                        </Typography>
                        <Typography variant="body2">
                          {option.weekly_price}
                        </Typography>
                        <Typography sx={{ fontWeight: "700" }}>
                          {option.uncut_price}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            justifyContent: "space-between",
                            background: "#bf000026",
                            padding: "1rem",
                            marginTop: "1rem",
                            borderRadius: ".25rem",
                          }}
                        >
                          <Typography
                            sx={{
                              maxWidth: { xs: "100%", sm: "50%" },
                              marginRight: { xs: "1rem", sm: "0" },
                              marginBottom: { xs: "1rem", sm: "0" },
                            }}
                          >
                            {option.discount_details?.discount_description}
                          </Typography>
                          <Typography>
                            {option.discount_details?.discount_amount}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Subtotal</Typography>
                          <Typography>{option.subtotal}</Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "1rem",
                          borderBottom: "1px solid #efefef",
                          paddingBottom: "1rem",
                        }}
                      >
                        <Typography color="primary" variant="h6">
                          Fee/s
                        </Typography>
                        {option.fees?.map((fee, idx) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="body2">
                                {fee.fee_name}
                              </Typography>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  variant="caption"
                                  sx={{ marginRight: ".5rem" }}
                                >
                                  {fee.fee_amount_first}
                                </Typography>
                                <Typography variant="body2">
                                  {fee.fee_amount_second}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography variant="h5" color="primary">
                          Total:
                        </Typography>
                        <Typography variant="h6" color="primary">
                          {option.total_price}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })} */}
            {quoteDetails?.options?.map((option) => {
              return (
                <Box
                  sx={{
                    marginTop: "2rem",
                    boxShadow: "0px 0px 5px #cecece",
                    padding: "1.5rem 1rem",
                    background:
                      quoteDetails?.quote?.selected_option_id ===
                      option?.option_id
                        ? lightMode
                          ? "#44b8001c"
                          : "#112d00"
                        : lightMode
                        ? "#ffffff"
                        : "#292929",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #efefef",
                      paddingBottom: "1rem",
                    }}
                  >
                    <FormatListBulletedIcon sx={{ marginRight: ".5rem" }} />
                    <Typography sx={{ fontWeight: "700" }}>
                      {option?.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      marginTop: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        overflow: "hidden",
                        width: { xs: "100%", md: "300px", lg: "400px" },
                        marginRight: { xs: "0", md: "2rem" },
                        marginBottom: { xs: "1.5rem", md: "0" },
                      }}
                    >
                      <Box
                        sx={{
                          backgroundImage: option?.courses[0]?.course?.images
                            ?.length
                            ? `url(${option?.courses[0]?.course?.images[0].replace(
                                "devcrm1",
                                "devcrm"
                              )})`
                            : "unset",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "left",
                          backgroundSize: "cover",
                          width: { xs: "100%", md: "400px" },
                          height: { xs: "300px", md: "100%" },
                          maxHeight: "400px",
                          filter: "blur(8px)",
                        }}
                      ></Box>
                      <img
                        src={
                          option?.courses[0]?.course?.images?.length
                            ? option?.courses[0]?.course?.images[0].replace(
                                "devcrm1",
                                "devcrm"
                              )
                            : "javascript:;"
                        }
                        style={{
                          position: "absolute",
                          inset: "0",
                          width: "90%",
                          left: "5%",
                          borderRadius: "1rem",
                          height: "100%",
                          maxHeight: "400px",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: {
                          xs: "100%",
                          md: "calc(100% - 332px)",
                          lg: "calc(100% - 432px)",
                        },
                      }}
                    >
                      {/* COURSES START */}
                      {option?.courses?.map((course, idx) => {
                        return option?.courses?.length > 1 && idx >= 1 ? (
                          coursesControl ? (
                            <>
                              <Box
                                sx={{
                                  padding: "1rem 0",
                                  boxShadow: "0px 0px 3px #cfcfcf",
                                  padding: "1rem",
                                  marginBottom: "1.5rem",
                                }}
                              >
                                <Box
                                  sx={{
                                    borderBottom: "1px solid #efefef",
                                    paddingBottom: "1rem",
                                  }}
                                >
                                  <Typography color="primary" variant="h6">
                                    <strong>
                                      {course?.option_course_partner}
                                    </strong>{" "}
                                    - {course?.option_course_campus}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <LocationOnIcon
                                      sx={{
                                        fontSize: "14px",
                                        color: "#bf0000",
                                      }}
                                    />
                                    <Typography variant="body2">
                                      {course?.course?.campus?.campus_address}
                                    </Typography>
                                  </Box>

                                  <Box sx={{ display: "flex" }}>
                                    <Typography
                                      color="primary"
                                      variant="body2"
                                      sx={{ marginRight: ".5rem" }}
                                    >
                                      Academic Year:
                                    </Typography>
                                    <Typography variant="body2">
                                      {course?.academic_year}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    marginTop: "1rem",
                                    // borderBottom: "1px solid #efefef",
                                    // paddingBottom: "1rem",
                                  }}
                                >
                                  <Typography color="primary" variant="h6">
                                    Accommodation/s
                                  </Typography>
                                  <Typography variant="body2">
                                    {course?.option_course_name}
                                  </Typography>
                                  <Typography variant="body2">
                                    {option?.duration}
                                  </Typography>

                                  <Typography variant="body2">
                                    {course?.option_course_start_date} -{" "}
                                    {course?.option_course_end_date} (
                                    {/* {course?.option_course_duration}{" "}
                                    {course?.option_course_duration > 1
                                      ? course?.option_course_duration_unit +
                                        "s"
                                      : course?.option_course_duration_unit}{" "} */}
                                    {course?.option_course_duration < 7
                                      ? course?.option_course_duration > 1
                                        ? course?.option_course_duration +
                                          ` days`
                                        : course?.option_course_duration +
                                          ` day`
                                      : course?.option_course_duration % 7 === 0
                                      ? course?.option_course_duration / 7 === 1
                                        ? course?.option_course_duration / 7 +
                                          ` week`
                                        : course?.option_course_duration / 7 +
                                          ` weeks`
                                      : Math.floor(
                                          course?.option_course_duration / 7
                                        ) +
                                        ` week${
                                          Math.floor(
                                            course?.option_course_duration / 7
                                          ) === 1
                                            ? ``
                                            : `s`
                                        } and ` +
                                        (course?.option_course_duration % 7) +
                                        ` day${
                                          course?.option_course_duration % 7 ===
                                          1
                                            ? ``
                                            : `s`
                                        } `}
                                    )
                                  </Typography>
                                  <Typography variant="body2">
                                    {paraFormat(
                                      course?.option_course_weekly_price
                                    )}{" "}
                                    / Week
                                  </Typography>
                                  <Typography sx={{ fontWeight: "700" }}>
                                    {paraFormat(
                                      course?.option_course_total_price
                                    )}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "1rem",
                                      borderBottom: "1px solid #efefef",
                                      paddingBottom: "1rem",
                                    }}
                                  >
                                    {/* <Typography variant="body1">
                                      Subtotal
                                    </Typography> */}
                                    <Typography variant="h6">
                                      {paraFormat(
                                        course?.option_course_gross_subtotal_price
                                      )}
                                    </Typography>
                                  </Box>
                                  {course?.promotion?.map((promotion) => {
                                    return (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: {
                                            xs: "column",
                                            sm: "row",
                                          },
                                          justifyContent: "space-between",
                                          background: "#44b8001c",
                                          padding: "1rem",
                                          marginTop: "1rem",
                                          borderRadius: ".25rem",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            maxWidth: {
                                              xs: "100%",
                                              sm: "50%",
                                            },
                                            marginRight: {
                                              xs: "1rem",
                                              sm: "0",
                                            },
                                            marginBottom: {
                                              xs: "1rem",
                                              sm: "0",
                                            },
                                          }}
                                        >
                                          {promotion?.promotion_type === "fixed"
                                            ? `${promotion?.promotion_name} (${promotion?.promotion_amount} GBP Discount)`
                                            : `${promotion?.promotion_name} (%
                                              ${promotion?.promotion_percentage} Discount)`}
                                        </Typography>
                                        <Typography>
                                          -{" "}
                                          {paraFormat(
                                            promotion?.promotion_amount
                                          )}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </Box>
                            </>
                          ) : null
                        ) : (
                          <>
                            <Box
                              sx={{
                                boxShadow: "0px 0px 3px #cfcfcf",
                                padding: "1rem",
                                marginBottom: "1.5rem",
                              }}
                            >
                              <Box
                                sx={{
                                  borderBottom: "1px solid #efefef",
                                  paddingBottom: "1rem",
                                }}
                              >
                                <Typography color="primary" variant="h6">
                                  <strong>
                                    {course?.option_course_partner}
                                  </strong>{" "}
                                  - {course?.option_course_campus}
                                </Typography>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <LocationOnIcon
                                    sx={{ fontSize: "14px", color: "#bf0000" }}
                                  />
                                  <Typography variant="body2">
                                    {course?.course?.campus?.campus_address}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: "flex" }}>
                                  <Typography
                                    color="primary"
                                    variant="body2"
                                    sx={{ marginRight: ".5rem" }}
                                  >
                                    Academic Year:
                                  </Typography>
                                  <Typography variant="body2">
                                    {course?.academic_year}
                                  </Typography>
                                </Box>
                                {/* <Typography variant="body2">
                                  {course?.option_course_start_date} -{" "}
                                  {course?.option_course_end_date} (
                                  {course?.option_course_duration}{" "}
                                  {course?.option_course_duration > 1
                                    ? course?.option_course_duration_unit + "s"
                                    : course?.option_course_duration_unit}{" "}
                                  )
                                </Typography> */}
                              </Box>
                              <Box
                                sx={{
                                  marginTop: "1rem",
                                  // paddingBottom: "1rem",
                                }}
                              >
                                <Typography color="primary" variant="h6">
                                  Accommodation/s
                                </Typography>
                                <Typography variant="body2">
                                  {course?.option_course_name}
                                </Typography>
                                <Typography variant="body2">
                                  {option?.duration}
                                </Typography>
                                <Typography variant="body2">
                                  {course?.option_course_start_date} -{" "}
                                  {course?.option_course_end_date} (
                                  {/* 
                                  {course?.option_course_duration}{" "}
                                  {course?.option_course_duration > 1
                                    ? course?.option_course_duration_unit + "s"
                                    : course?.option_course_duration_unit}{" "} */}
                                  {course?.option_course_duration < 7
                                    ? course?.option_course_duration > 1
                                      ? course?.option_course_duration + ` days`
                                      : course?.option_course_duration + ` day`
                                    : course?.option_course_duration % 7 === 0
                                    ? course?.option_course_duration / 7 === 1
                                      ? course?.option_course_duration / 7 +
                                        ` week`
                                      : course?.option_course_duration / 7 +
                                        ` weeks`
                                    : Math.floor(
                                        course?.option_course_duration / 7
                                      ) +
                                      ` week${
                                        Math.floor(
                                          course?.option_course_duration / 7
                                        ) === 1
                                          ? ``
                                          : `s`
                                      } and ` +
                                      (course?.option_course_duration % 7) +
                                      ` day${
                                        course?.option_course_duration % 7 === 1
                                          ? ``
                                          : `s`
                                      } `}
                                  )
                                </Typography>
                                <Typography variant="body2">
                                  {paraFormat(
                                    course?.option_course_weekly_price
                                  )}{" "}
                                  / Week
                                </Typography>
                                <Typography sx={{ fontWeight: "700" }}>
                                  {paraFormat(
                                    course?.option_course_total_price
                                  )}
                                </Typography>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "1rem",
                                    // borderBottom: "1px solid #efefef",
                                    // paddingBottom: "1rem",
                                  }}
                                >
                                  {/* <Typography variant="body1">
                                    Subtotal
                                  </Typography> */}
                                  <Typography variant="h6">
                                    {paraFormat(
                                      option?.courses[0]
                                        ?.option_course_gross_subtotal_price
                                    )}
                                  </Typography>
                                </Box>
                                {course?.promotion?.map((promotion) => {
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: {
                                          xs: "column",
                                          sm: "row",
                                        },
                                        justifyContent: "space-between",
                                        background: "#44b8001c",
                                        padding: "1rem",
                                        marginTop: "1rem",
                                        borderRadius: ".25rem",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          maxWidth: { xs: "100%", sm: "50%" },
                                          marginRight: { xs: "1rem", sm: "0" },
                                          marginBottom: { xs: "1rem", sm: "0" },
                                        }}
                                      >
                                        {/* {promotion?.promotion_name} (%
                                        {promotion?.promotion_percentage}{" "}
                                        Discount) */}
                                        {promotion?.promotion_type === "fixed"
                                          ? `${promotion?.promotion_name} (${promotion?.promotion_amount} GBP Discount)`
                                          : `${promotion?.promotion_name} (%
                                              ${promotion?.promotion_percentage} Discount)`}
                                      </Typography>
                                      <Typography>
                                        -{" "}
                                        {paraFormat(
                                          promotion?.promotion_amount
                                        )}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          </>
                        );
                      })}
                      {option?.courses?.length > 1 ? (
                        coursesControl ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleChangeCourseControl()}
                          >
                            Hide
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleChangeCourseControl()}
                          >
                            See All Courses
                          </Button>
                        )
                      ) : null}

                      {/* COURSES END */}

                      <Box
                        sx={{
                          marginTop: "1rem",
                          borderBottom: "1px solid #efefef",
                          paddingBottom: "1rem",
                        }}
                      >
                        <Typography color="primary" variant="h6">
                          Fee/s
                        </Typography>
                        {option?.services?.map((service, idx) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="body2">
                                {service?.option_service_name}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  sx={{ marginRight: ".5rem" }}
                                >
                                  +VAT (
                                  {paraFormat(service?.option_service_vat)})
                                </Typography>
                                <Typography variant="body2">
                                  {paraFormat(
                                    service?.option_service_gross_price
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid  #efefef",
                          paddingBottom: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography variant="h6">Sub Total:</Typography>
                        <Typography variant="h6">
                          {paraFormat(option?.subtotal)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography variant="h5" color="primary">
                          Total:
                        </Typography>
                        <Typography variant="h5" color="primary">
                          {paraFormat(option?.total)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </>
        </Box>
      </Box>
    </>
  );
}
