import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import AirportServicesContext from "../../../../../../contexts/AirportServices/AirportServices";
import PersonIcon from "@mui/icons-material/Person";
import LuggageIcon from "@mui/icons-material/Luggage";
import BackpackIcon from "@mui/icons-material/Backpack";

export default function CarSingleCart({ car }) {
  const { setSelectedCar, setBookingDetails, activeStep, setActiveStep } = useContext(
    AirportServicesContext
  );

  // HTML etiketlerini kaldırarak yalnızca metin içeriğini al
  const extractTextFromHtml = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        marginBottom: "1.5rem",
        borderBottom: "1px solid #efefef",
      }}
    >
      <Box>
        <img src={car?.image} width={200} height={200} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
        <Typography variant="h5">{car?.title}</Typography>
        <Typography variant="h6" sx={{ marginTop: ".5rem" }}>
          {extractTextFromHtml(car?.description)}
        </Typography>
        <Box sx={{ display: "flex", marginTop: "1rem" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PersonIcon sx={{ color: "#bf0000" }} />
            <Typography sx={{ marginLeft: ".5rem" }}>
              {car?.seats} Seats
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          >
            <LuggageIcon sx={{ color: "#bf0000" }} />
            <Typography sx={{ marginLeft: ".5rem" }}>
              {car?.luggage} Luggage
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <BackpackIcon sx={{ color: "#bf0000" }} />
            <Typography sx={{ marginLeft: ".5rem" }}>
              {car?.hand_luggage} Hand Luggage
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            marginTop: "1.5rem",
          }}
        >
          <Typography variant="h6" color={"primary"}>
            Price: £{car?.price}
          </Typography>
          <Button
            onClick={() => (
              setSelectedCar(car?.id),
              setBookingDetails((prev) => ({
                ...prev,
                car_id: car?.id,
              })),
              setActiveStep(activeStep + 1)
            )}
            sx={{
              width: { xs: "100%", md: "fit-content" },
              marginLeft: { xs: "0", md: "2rem" },
            }}
            variant="contained"
            color="primary"
          >
            Book Now
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
