import { Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { getTheme } from "../../../styles/theme";

export default function CartCounts({
  itemId,
  handleDecreaseCount,
  handleIncreaseCount,
  handleRemoveItem,
  isMobile,
  serviceType
}) {
  const cart = useSelector((state) =>
    serviceType === "products"
      ? state.cart.products?.cart
      : state.cart.services?.cart
  );
  const itemInCart = cart.find((cartItem) => cartItem.service_id === itemId);
  const count = itemInCart ? itemInCart.quantity : 0;
  return (
    <Box
      sx={{
        justifyContent: { xs: "flex-start", md: "center" },
        display: "flex",
        alignItems: "center",
        boxShadow: isMobile
          ? "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
          : "unset",
      }}
    >
      <Typography
        sx={{
          marginRight: "1rem",
          fontWeight: "700",
          display: { xs: "none", md: "block" },
        }}
        variant="body1"
      >
        Count
      </Typography>
      <Box
        sx={{
          display: "flex",
          borderRadius: "4px",
          overflow: "hidden",
          alignItems: "center",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Box
          onClick={handleDecreaseCount}
          sx={{
            padding: "1rem",
            backgroundColor: "#bf0000",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          {count == 1 ? (
            <DeleteIcon sx={{ color: "#ffffff" }} onClick={handleRemoveItem} />
          ) : (
            `-`
          )}
        </Box>
        <Box
          sx={{
            marginLeft: ".5rem",
            marginRight: ".5rem",
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
          }}
        >
          {count}
        </Box>
        <Box
          onClick={handleIncreaseCount}
          sx={{
            padding: "1rem",
            backgroundColor: "#bf0000",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          +
        </Box>
      </Box>
    </Box>
  );
}
