import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Typography,
} from "@mui/material";
import ServiceItems from "../../components/Shop/ServiceItems";
import BreadCrumbs from "../../components/Breadcrumbs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound";
import { openModal, setPageType } from "../../redux/actions/actions";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import { current } from "@reduxjs/toolkit";
import ComingSoonModal from "../../components/Modal/ComingSoonModal/ComingSoonModal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
export default function Shop() {
  const itemsPerPage = 10; // Her sayfada kaç öğe gösterileceğini belirleyin
  const [currentPage, setCurrentPage] = useState(); // Şu anki sayfayı saklamak için bir değişken
  const [selectedDate, setSelectedDate] = useState(null); // Şu anki sayfayı saklamak için bir değişken
  const [selectedSize, setSelectedSize] = useState(null);

  const [serviceItemsData, setServiceItemsData] = useState([]);
  const [allServiceItemsData, setAllServiceItemsData] = useState([]);
  const [refreshControl, setRefreshControl] = useState(true);
  const [loader, setLoader] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  const [categories, setCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState();

  const cart = useSelector((state) => state.cart.products?.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // URL bilgisine erişim sağlar

  useEffect(() => {
    dispatch(setPageType("additional_services"));
    if (currentPage) {
      fetchServiceItems(currentPage, selectedCategory);
    }
  }, [currentPage, selectedCategory]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageFromURL = parseInt(queryParams.get("page") || "1", 10);
    const categoryFromURL = parseInt(
      queryParams.get("category_id") || null,
      10
    );

    setCurrentPage(pageFromURL);
    setSelectedCategory(categoryFromURL);
  }, [location.search]);

  const fetchServiceItems = (page, selectedCategory) => {
    setLoader(true);
    const accessToken = localStorage.getItem("accessToken");
    const token = localStorage.getItem("loginUser");

    let url = selectedCategory
      ? `${process.env.REACT_APP_BASE_URL}services?parent=Shop&page=${page}&category_id=${selectedCategory}`
      : `${process.env.REACT_APP_BASE_URL}services?parent=Shop&page=${page}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          XRefreshToken: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setServiceItemsData(response.data);
        const totalItems = response.data.count;
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
      })
      .catch((error) => {
        console.error("There was an error fetching the service items:", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);
  const getCategories = () => {
    const accessToken = localStorage.getItem("accessToken");
    const token = localStorage.getItem("loginUser");

    axios
      .get(`${process.env.REACT_APP_BASE_URL}service_categories`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          XRefreshToken: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error("There was an error fetching the service items:", error);
      });
  };

  const getServicesForCategory = (categoryId) => {
    setLoader(true);
    const accessToken = localStorage.getItem("accessToken");
    const token = localStorage.getItem("loginUser");

    navigate(`?page=${currentPage}&category_id=${categoryId}`);

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}services?category_id=${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            XRefreshToken: `${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setServiceItemsData(response.data);
        const totalItems = response.data.count;
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
        setSelectedCategory(categoryId);
        setLoader(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the service items:", error);
        setLoader(false);
      });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // Burada ek olarak, URL'de sayfa numarasını güncelleyebilirsiniz.
    if (selectedCategory) {
      if (value !== 1) {
        navigate(`?page=${value}&category_id=${selectedCategory}`);
      } else {
        navigate(`?category_id=${selectedCategory}`);
      }
    } else {
      if (value !== 1) {
        navigate(`?page=${value}`);
      } else {
        navigate(``);
      }
    }
  };

  // useEffect(() => {
  //   dispatch(setPageType("additional_services"));

  //   let accessToken = localStorage.getItem("accessToken");
  //   let token = localStorage.getItem("loginUser");
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}services?page=1`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
  //         XRefreshToken: `${token}`, // Bearer token burada ekleniyor
  //         "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
  //       },
  //     })

  //     .then(function (response) {
  //       // handle success
  //       setServiceItemsData(response.data);
  //       setLoader(false);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //       setLoader(false);
  //     })
  //     .finally(function () {
  //       // always executed
  //       setLoader(false);
  //     });
  // }, []);

  // useEffect(() => {
  //   console.log(selectedDate, "selected date!!");
  // }, [selectedDate]);

  // useEffect(() => {
  //   if (!allServiceItemsData.length) {
  //     setAllServiceItemsData(serviceItemsData);
  //   }
  // }, [serviceItemsData]);

  // useEffect(() => {
  //   if (serviceItemsData.length) {
  //     if (!allServiceItemsData.length) {
  //       // serviceItemsData'nın bir kopyasını oluşturmak yerine, doğrudan üzerinde çalışıyoruz çünkü zaten bir dizi
  //       const loadImage = (item) => {
  //         return new Promise((resolve, reject) => {
  //           const image = new Image();
  //           image.src = item.image;
  //           image.onload = () => resolve({ ...item, isValidImage: true });
  //           image.onerror = () => resolve({ ...item, isValidImage: false });
  //         });
  //       };

  //       const checkImages = async () => {
  //         const promises = serviceItemsData.map((item) => loadImage(item));
  //         const results = await Promise.all(promises);
  //         setAllServiceItemsData(results);
  //       };

  //       checkImages();
  //     }
  //   }
  // }, [serviceItemsData, allServiceItemsData]);

  // useEffect(() => {
  //   // Servis verilerini çeken fonksiyon (örneğin API'den veri çekme)
  //   if (allServiceItemsData.length) {
  //     const fetchServiceItems = async () => {
  //       // API'den tüm servis verilerini çekme işlemi burada yapılır
  //       // Dummy data kullanılarak simüle edilmiştir

  //       // URL'den kategori parametresini çıkarma
  //       const queryParams = new URLSearchParams(location.search);
  //       const categoryFromURL = queryParams.get("category");

  //       if (categoryFromURL) {
  //         setRefreshControl(true);
  //         // URL'deki kategoriye göre filtreleme yapma
  //         const filteredServicesData = allServiceItemsData.filter(
  //           (serviceItem) => {
  //             const serviceItemFormattedCategory = serviceItem.category
  //               .toLowerCase()
  //               .replace(/ /g, "_");

  //             return serviceItemFormattedCategory === categoryFromURL;
  //           }
  //         );
  //         setServiceItemsData(filteredServicesData);
  //         setRefreshControl(false);
  //       } else {
  //         // Eğer URL'de kategori belirtilmemişse, tüm verileri ayarla
  //         setServiceItemsData(allServiceItemsData);
  //         setRefreshControl(false);
  //       }
  //     };
  //     fetchServiceItems();
  //   }
  // }, [allServiceItemsData]); // URL'deki arama sorgusu değiştiğinde bu useEffect tekrar çalışır

  // const getItemsForCurrentPage = () => {
  //   const startIndex = (currentPage - 1) * itemsPerPage;
  //   const endIndex = startIndex + itemsPerPage;
  //   return serviceItemsData.slice(startIndex, endIndex);
  // };

  // const handleCategoryFilter = (category) => {
  //   // serviceItemsData dizisinde filter metodunu kullanarak filtreleme yapılıyor.
  //   let filteredServicesData = allServiceItemsData.filter((serviceItem) => {
  //     return serviceItem.category === category;
  //   });
  //   const formattedCategory = category.toLowerCase().replace(/ /g, "_");

  //   // Filtrelenmiş sonuç setServiceItemsData ile güncelleniyor.
  //   navigate(`/shop?category=${formattedCategory}`);
  //   setCurrentPage(1);
  //   setServiceItemsData(filteredServicesData);
  // };
  const handleOpenModal = () => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    dispatch(openModal(<ComingSoonModal />));
  };
  return (
    <>
      <Typography className="title" variant="h4">
        Shop
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Shop"]} />
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {categories?.map((category, idx) => {
              return category?.id !== 1 &&
                category?.id !== 2 &&
                category?.id !== 3 &&
                category?.id !== 11 &&
                category?.id !== 6 &&
                category?.id !== 7 &&
                category?.id !== 8 &&
                category?.id !== 13 &&
                category?.id !== 5 ? (
                selectedCategory === category?.id ? (
                  <Box
                    key={idx}
                    sx={{
                      display: "inline-block",
                      position: "relative",
                      marginRight: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <Button
                      variant={`${
                        selectedCategory === category?.id
                          ? `contained`
                          : `outlined`
                      }`}
                      color="primary"
                      // onClick={() => getServicesForCategory(category?.id)}
                      onClick={() => {
                        if (category?.id === 11 || category?.id === 3) {
                          handleOpenModal();
                        } else if (!loader) {
                          setSelectedCategory(category?.id);
                          setCurrentPage(1);
                          navigate(`?category_id=${category?.id}`);
                        }
                      }}
                    >
                      {category?.parent_name === "Service" &&
                      category?.category_name === "Other"
                        ? category?.category_name + " Services"
                        : category?.category_name}
                    </Button>

                    <CancelOutlinedIcon
                      onClick={() => (
                        setSelectedCategory(), setCurrentPage(1), navigate(``)
                      )}
                      sx={{
                        cursor: "pointer",
                        background: "white",
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        color: "#b51110",
                        zIndex: "9",
                        borderRadius: "100%",
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    key={idx}
                    sx={{
                      display: "inline-block",
                      position: "relative",
                      marginRight: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <Button
                      variant={`${
                        selectedCategory === category?.id
                          ? `contained`
                          : `outlined`
                      }`}
                      color="primary"
                      // onClick={() => getServicesForCategory(category?.id)}
                      onClick={() => {
                        if (category?.id === 11 || category?.id === 3) {
                          handleOpenModal();
                        } else if (!loader) {
                          setSelectedCategory(category?.id);
                          setCurrentPage(1);
                          navigate(`?category_id=${category?.id}`);
                        }
                      }}
                    >
                      {category?.parent_name === "Service" &&
                      category?.category_name === "Other"
                        ? category?.category_name + " Services"
                        : category?.category_name}
                    </Button>
                  </Box>
                )
              ) : null;
            })}
          </Grid>
          {!loader ? (
            serviceItemsData?.results?.length ? (
              <>
                {
                  // getItemsForCurrentPage().map((item, idx) => (
                  //   <ServiceItems
                  //     item={item}
                  //     key={item.service_id}
                  //     selectedDate={selectedDate}
                  //     setSelectedDate={setSelectedDate}
                  //   />
                  // ))
                  serviceItemsData?.results.map((item, idx) => (
                    <ServiceItems
                      item={item}
                      key={item.service_id}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      selectedSize={selectedSize}
                      setSelectedSize={setSelectedSize}
                      serviceType={"products"}
                    />
                  ))
                }
                <Grid item xs={12}>
                  <Pagination
                    variant="outlined"
                    shape="rounded"
                    sx={{ mt: 10, display: "flex", justifyContent: "center" }}
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </Grid>
              </>
            ) : (
              <NoDataFound />
            )
          ) : (
            <CircularProgress
              sx={{ position: "fixed", left: "50%", top: "50%" }}
            />
          )}
        </Grid>
      </>
    </>
  );
}
