import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../actions/actions";
import { getTheme } from "../../styles/theme";
import { useLocation, useNavigate } from "react-router-dom";
import CartItems from "./CartItems";
import { openModal } from "../../redux/actions/actions";
import FilterModal2 from "../Modal/FilterModal";
import ShoppingCartMobileModal from "../Modal/ShoppingCartMobileModal";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";

export default function CartHeader() {
  const [selectedCartType, setSelectedCartType] = useState("products");
  const cartProducts = useSelector((state) => state.cart.products?.cart);
  const cartServices = useSelector((state) => state.cart.services?.cart);

  const [openedShoppingCart, setOpenedShoppingCart] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const mobileDevice = useSelector((state) => state.windowWidth.width);

  const cartRef = useRef();

  const handleOpenModal = (cartProducts) => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    dispatch(
      openModal(
        <ShoppingCartMobileModal navigate={navigate} location={location} />
      )
    );
  };

  const handleOpenShoppingCart = () => {
    if (mobileDevice > 768) {
      setOpenedShoppingCart(!openedShoppingCart);
    } else {
      handleOpenModal(cartProducts);
    }
  };

  // Dış tıklamaları kontrol etmek için bir fonksiyon
  const handleClickOutside = (event) => {
    if (cartRef.current && !cartRef.current.contains(event.target)) {
      setOpenedShoppingCart(false);
    }
  };

  useEffect(() => {
    // Dış tıklamaları dinlemek için olay dinleyicisini ekleyin
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Component unmount edildiğinde olay dinleyicisini kaldırın
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlerDesktop = () => {
    if (mobileDevice > 768) {
      setOpenedShoppingCart(false);
    }
  };

  const dispatch = useDispatch();
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  // useEffect(() => {
  //   console.log(selectedCartType, "selectedCartType");
  // }, [selectedCartType]);

  const calculateTotalQuantity = () => {
    let cartProductQuantity = 0;
    let cartServicesQuantity = 0;

    cartProducts.forEach((cartProduct) => {
      cartProductQuantity += cartProduct?.quantity || 0;
    });

    cartServices.forEach((cartService) => {
      cartServicesQuantity += cartService?.quantity || 0;
    });

    return cartProducts.length || cartServices.length
      ? Number(cartProductQuantity) + Number(cartServicesQuantity)
      : null;
  };

  return (
    <>
      <Box ref={cartRef} sx={{ position: "relative", marginLeft: "2rem" }}>
        <Box
          onClick={handleOpenShoppingCart}
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <ShoppingCartIcon
            sx={{
              fontSize: mobileDevice > 768 ? "32px" : "24px",
            }}
          />
          {cartProducts?.length || cartServices?.length ? (
            <Box
              sx={{
                width: "22px",
                height: "22px",
                borderRadius: "100%",
                filter: lightMode && "drop-shadow(2px 2px 2px #707070)",
                position: "absolute",
                top: "-12px",
                right: "-12px",
                background: getTheme().palette.customRed.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                }}
              >
                {calculateTotalQuantity()}
              </Typography>
            </Box>
          ) : null}
        </Box>
        {openedShoppingCart && mobileDevice > 768 ? (
          <Box
            sx={{
              minWidth: "320px",
              minHeight: "max-content",
              width: "max-content",
              position: "absolute",
              top: "64px",
              right: "0",
              overflow: "hidden",
              borderRadius: "6px",
              background: lightMode ? "#ededed" : "#121212",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                padding: "1.25rem",
                backgroundColor: lightMode ? "#efefef" : "#242424",
                color: lightMode ? "#000000" : "#ffffff",
                borderBottom: "1px solid #ffffff",
              }}
            >
              Basket
            </Typography>
            <Box
              sx={{
                display: "flex",
                padding: "1rem",
                backgroundColor: lightMode ? "#efefef" : "#242424",
                borderBottom: `1px solid ${lightMode ? "#ffffff" : "#efefef"}`,
              }}
            >
              <Box
                onClick={() => setSelectedCartType("services")}
                sx={{
                  width: "50%",
                  textAlign: "center",
                  padding: ".5rem",
                  cursor: "pointer",
                  boxShadow:
                    selectedCartType === "services"
                      ? "rgb(1 1 1 / 10%) 0px 2px 5px 2px"
                      : "unset",
                }}
              >
                <Typography variant="h6">
                  Services ({cartServices?.length})
                </Typography>
              </Box>
              <Box
                onClick={() => setSelectedCartType("products")}
                sx={{
                  width: "50%",
                  textAlign: "center",
                  padding: ".5rem",
                  cursor: "pointer",
                  boxShadow:
                    selectedCartType === "products"
                      ? "rgb(1 1 1 / 10%) 0px 2px 5px 2px"
                      : "unset",
                }}
              >
                <Typography variant="h6">
                  Products ({cartProducts?.length})
                </Typography>
              </Box>
            </Box>
            <Box sx={{ overflow: "auto", maxHeight: "50vh" }}>
              {selectedCartType === "products" ? (
                cartProducts.length ? (
                  cartProducts?.map((item, idx) => {
                    return (
                      <CartItems
                        item={item}
                        idx={idx}
                        serviceType={
                          selectedCartType === "products"
                            ? "products"
                            : "services"
                        }
                      />
                    );
                  })
                ) : (
                  <Box
                    sx={{
                      padding: "2rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: "1rem",
                        position: "relative",
                      }}
                    >
                      <ShoppingBasketIcon
                        sx={{
                          fontSize: "72px",
                          color: lightMode ? "#000000" : "#ffffff",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        color: lightMode ? "#000000" : "#ffffff",
                        fontSize: "24px",
                      }}
                    >
                      Basket is Empty
                    </Typography>
                  </Box>
                )
              ) : cartServices.length ? (
                cartServices?.map((item, idx) => {
                  return (
                    <CartItems
                      item={item}
                      idx={idx}
                      serviceType={
                        selectedCartType === "products"
                          ? "products"
                          : "services"
                      }
                    />
                  );
                })
              ) : (
                <Box
                  sx={{
                    padding: "2rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: "1rem",
                      position: "relative",
                    }}
                  >
                    <ShoppingBasketIcon
                      sx={{
                        fontSize: "72px",
                        color: lightMode ? "#000000" : "#ffffff",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: lightMode ? "#000000" : "#ffffff",
                      fontSize: "24px",
                    }}
                  >
                    Basket is Empty!
                  </Typography>
                </Box>
              )}
            </Box>

            {selectedCartType === "products" ? (
              cartProducts.length ? (
                location.pathname === "/payment/shop" ? (
                  <Button
                    fullWidth
                    sx={{
                      minHeight: "50px !important",
                      fontSize: "18px",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate("/shop");
                      handlerDesktop();
                    }}
                  >
                    Continue to Shopping
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    sx={{
                      minHeight: "50px !important",
                      fontSize: "18px",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate("payment/shop");

                      handlerDesktop();
                    }}
                  >
                    Continue to Payment
                  </Button>
                )
              ) : (
                <Button
                  fullWidth
                  sx={{
                    minHeight: "50px !important",
                    fontSize: "18px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/shop");

                    handlerDesktop();
                  }}
                >
                  Continue to Shopping
                </Button>
              )
            ) : cartServices.length ? (
              location.pathname === "/payment/services" ? (
                <Button
                  fullWidth
                  sx={{
                    minHeight: "50px !important",
                    fontSize: "18px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/additional-services/cleaning-services");
                    handlerDesktop();
                  }}
                >
                  Continue to Shopping
                </Button>
              ) : (
                <Button
                  fullWidth
                  sx={{
                    minHeight: "50px !important",
                    fontSize: "18px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("payment/services");

                    handlerDesktop();
                  }}
                >
                  Continue to Payment
                </Button>
              )
            ) : (
              <Button
                fullWidth
                sx={{
                  minHeight: "50px !important",
                  fontSize: "18px",
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("additional-services/cleaning-services");

                  handlerDesktop();
                }}
              >
                Continue to Shopping
              </Button>
            )}

            {/* {cartProducts.length || cartServices.length ? (
              <Button
                fullWidth
                sx={{
                  minHeight: "50px !important",
                  fontSize: "18px",
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (selectedCartType === "products") {
                    if (
                      location.pathname === "/payment/shop" ||
                      location.pathname === "/payment/services"
                    ) {
                      navigate("shop");
                    } else {
                      navigate("payment/shop");
                    }
                  } else if (selectedCartType === "services") {
                    if (
                      location.pathname === "/payment/services" ||
                      location.pathname === "/payment/shop"
                    ) {
                      navigate("additional-services/cleaning-services");
                    } else {
                      navigate("payment/services");
                    }
                  }

                  handlerDesktop();
                }}
              >
                {location.pathname === "/payment/shop" ||
                location.pathname === "/payment/services"
                  ? `Continue to Shopping`
                  : `Continue to Payment`}
              </Button>
            ) : (
              <Button
                fullWidth
                sx={{
                  minHeight: "50px !important",
                  fontSize: "18px",
                }}
                onClick={() => {
                  if (selectedCartType === "products") {
                    navigate("shop");
                  } else if (selectedCartType === "services") {
                    navigate("additional-services/cleaning-services");
                  }

                  handlerDesktop();
                }}
                // onClick={() => (navigate("shop"), handlerDesktop())}
                variant="contained"
                color="primary"
              >
                Continue to Shopping
              </Button>
            )} */}
          </Box>
        ) : null}
      </Box>
    </>
  );
}
