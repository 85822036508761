import { useEffect, useState } from "react";
import FilterPagesGeneral from "../../components/FilterPagesGeneral";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearCart } from "../../redux/actions/cartActions";
import { openModal } from "../../redux/actions/actions";
import PaymentSuccessModal from "../../components/Modal/PaymentSuccessModal/PaymentSuccessModal";
import Swal from "sweetalert2";

export default function MyInvoices() {
  const filterMethods = [
    "student_name",
    "issue_date",
    "check_in",
    "check_out",
    "status",
    "duration",
  ];

  const location = useLocation();
  const dispatch = useDispatch();

  const [basketType, setBasketType] = useState();

  const handleOpenModal = (status) => {
    // Dispatch an action to open the modal, with the FilterModal component as the payload
    // dispatch(openModal(<PaymentSuccessModal status={status} />, status));
    if (status === "success") {
      Swal.fire({
        icon: "success",
        title: `Payment Successful!`,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: `Payment Failed!`,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
  };
  const queryParams = new URLSearchParams(location.search);
  const serviceBasket = queryParams.get("service_basket");
  const productBasket = queryParams.get("product_basket");

  useEffect(() => {
    const status = queryParams.get("status");


    if (status === "success") {
      if (basketType) {
        dispatch(clearCart(basketType));
        handleOpenModal(status);
      }
    } else if (status === "success") {
      handleOpenModal(status);
      dispatch(clearCart(basketType));
    } else if (status === "failed" && !basketType) {
      handleOpenModal(status);
    }
  }, [location, basketType, dispatch]);

  useEffect(() => {
    if (serviceBasket === "clear") {
      setBasketType("services");
    } else if (productBasket === "clear") {
      setBasketType("products");
    }
  }, [location]);

  return (
    <FilterPagesGeneral pageName={"invoices"} filterMethods={filterMethods} />
  );
}
