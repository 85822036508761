import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../../axiosInstance";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { getTheme } from "../../../styles/theme";
import { useSelector } from "react-redux";
import DateRangeContext from "../../../contexts/Dashboard/DateRange";
import axios from "axios";

export default function PerformanceCards({ cards, setCards }) {
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const profile = useSelector((state) => state?.profile?.profile);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin
  const [loading, setLoading] = useState(true);
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateRangeContext);

  const paraFormat = (number) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(number);
  };

  const getPerformances = async () => {
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");

    if (window.cancelRequest) {
      window.cancelRequest.cancel(
        "New request made, cancelling previous request."
      );
    }
    window.cancelRequest = axios.CancelToken.source(); // Yeni bir Cancel Token oluştur

    try {
      setLoading(true);
      let accessToken = localStorage.getItem("accessToken");
      let token = localStorage.getItem("loginUser");
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}dashboard/performance?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        {
          cancelToken: window.cancelRequest.token, // İptal tokenini istek ile gönder
          headers: {
            Authorization: `Bearer ${accessToken}`,
            XRefreshToken: `${token}`,
            Accounts: selectedBranchesString,
            "Content-Type": "application/json",
          },
        }
      );
      // API'den gelen veriyi işleme ve mevcut kartlara atama
      const updatedCards = cards.map((card) => {
        // Kartın id'sine göre API'den gelen veriyi al
        const apiValue = response.data[card.id];

        // total_commissions ve total_sales değerlerini paraFormat fonksiyonu ile formatlama
        if (card.id === "total_commissions" || card.id === "total_sales") {
          const formattedValue = paraFormat(apiValue);
          return { ...card, value: formattedValue };
        }

        if (apiValue !== undefined) {
          return { ...card, value: apiValue };
        }
        return card;
      });

      console.log(profile, "profile 6162");
      if (profile?.account_type !== "main_branch") {
        let newCards = updatedCards.filter((item) => {
          return item.id !== "total_commissions";
        });
        setCards(newCards);
      } else {
        setCards(updatedCards);
      }

      console.log(updatedCards, "updated cards");
      // setCards(updatedCards); // Güncellenmiş kartları set et
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled:", error.message);
      } else {
        setLoading(false);
        console.error("API isteği sırasında hata oluştu:", error);
      }
    }
  };
  useEffect(() => {
    console.log(selectedBranchesString, "here 6161");
    // if (selectedBranchesString?.length) {
    //   getPerformances();
    // }
    getPerformances();
  }, [startDate, endDate, selectedBranchesString, profile]);

  return cards?.map((card, idx) => (
    <Grid
      key={idx}
      item
      xs={12}
      sm={6}
      lg={profile?.account_type === "main_branch" ? 3 : 4}
      xl={profile?.account_type === "main_branch" ? 3 : 4}
    >
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 1rem 1rem 20px",
          position: "relative",
          height: "100%",
          background: lightMode ? "#ffffff" : "#292929",
          "&:before": {
            content: "''",
            position: "absolute",
            left: "0",
            top: "0",
            width: "4px",
            height: "100%",
            backgroundColor: getTheme().palette.customRed.dark,
          },
        }}
      >
        {loading ? (
          <CircularProgress sx={{ margin: "auto" }} />
        ) : (
          <>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto", padding: "0" }}>
                <Typography component="div" variant="h5">
                  {card.name}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  {card.value}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pl: 1,
                  pb: 1,
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                background: "#f7f7f7",
                width: "70px",
                height: "70px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100%",
                filter: `drop-shadow(2px 2px 1px ${
                  getTheme().palette.customRed.dark
                })`,
                padding: ".5rem",
              }}
            >
              {React.createElement(card.icon, {
                sx: {
                  fontSize: "48px",
                  color: getTheme().palette.customRed.dark,
                  filter: "drop-shadow(2px 2px 2px #aeaeae)",
                },
              })}
            </Box>
          </>
        )}
      </Card>
    </Grid>
  ));
}
