import { Box, Button, Typography } from "@mui/material";
import SwiperService from "./SwiperService";
import ItemComponentThumbs from "../../Swiper/thumbsSwiper/ItemComponent";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import CartCounts from "./CartCounts";

import { useDispatch, useSelector } from "react-redux";
import {
  setAddToCart,
  setDecrementQuantity,
  setIncrementQuantity,
  setRemoveFromCart,
  setUpdateCart,
} from "../../../redux/actions/cartActions";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

export default function AddToCartModal({
  item,
  selectedDate,
  setSelectedDate,
  selectedSize,
  setSelectedSize,
  serviceType,
}) {
  const [selected, setSelected] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  const cart = useSelector((state) =>
    serviceType === "products"
      ? state.cart.products?.cart
      : state.cart.services?.cart
  );
  const itemInCart = cart?.find(
    (cartItem) => cartItem.service_id === item.service_id
  );
  const itemCount = itemInCart ? itemInCart?.quantity : 0;
  const serviceDate = itemInCart ? dayjs(itemInCart?.serviceDate) : null;

  // useEffect(() => {
  //   console.log(cart, "CARTTT");
  // }, [cart]);

  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      setSelectedDate(null);
      setSelectedSize(null);
    };
  }, []);

  const dispatch = useDispatch();

  const handleIncreaseCount = () => {
    dispatch(
      setIncrementQuantity(
        item.service_id,
        serviceType === "products" ? "products" : "services"
      )
    );
  };

  const handleDecreaseCount = () => {
    dispatch(
      setDecrementQuantity(
        item.service_id,
        serviceType === "products" ? "products" : "services"
      )
    );
  };

  const handleRemoveItem = () => {
    dispatch(
      setRemoveFromCart(
        item.service_id,
        serviceType === "products" ? "products" : "services"
      )
    );
  };

  const handleDateTimeChange = (newDate) => {
    setSelected(newDate);
  };

  const handleDateChange = (newDate) => {
    // Tarih değiştiğinde yapılacak işlemleri burada tanımlayabilirsiniz.
    if (selected !== null) {
      const formattedDate = selected.toISOString();
      setSelectedDate(formattedDate);
      // Başka işlemler
    }
    // Başka işlemler
  };

  // const serviceDescription = item?.service_description?.replace(/<\/?p>/g, "");
  const serviceDescription = item?.service_description?.replace(
    /<\/?(p|li|ul|h3)>/g,
    ""
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   console.log(selectedSize, "selected size");
  // }, [selectedSize]);

  // useEffect(() => {
  //   console.log(cart, "this is cart");
  // }, [cart]);

  useEffect(() => {
    if (selectedDate) {
      if (!serviceDate) {
        console.log(cart.serviceDate, "selected date 3");
        dispatch(
          setAddToCart(
            item,
            selectedDate,
            selectedSize,
            serviceType === "products" ? "products" : "services"
          )
        ); // selectedDate burada Redux state'ine eklenir
      } else {
        dispatch(
          setUpdateCart(
            item,
            selectedDate,
            selectedSize,
            serviceType === "products" ? "products" : "services"
          )
        ); // 'products' cartType bilgisi eklendi
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedSize) {
      dispatch(
        setUpdateCart(
          item,
          selectedDate,
          selectedSize,
          serviceType === "products" ? "products" : "services"
        )
      ); // 'products' cartType bilgisi eklendi
    }
  }, [selectedSize]);

  // useEffect(()=> {
  //   cart?.map((cartItem) => {
  //     if(cartItem.service_id === item.service_id) {}
  //   })
  // }, [cart])

  let serviceImages = new Array(1).fill(item.image);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };

  return (
    <Box
      sx={{
        minWidth: { xs: "calc(90vw)", md: "auto" },
        left: { xs: "-16px", md: "0", position: "relative" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          padding: "1rem",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
              // position: "sticky",
              // top: "31px",
              // left: "0",
              // background: "#fff",
            },
            paddingRight: { xs: "0", md: "1rem" },
            height: "fit-content",
          }}
        >
          <ItemComponentThumbs
            dublicated={[false]}
            items={serviceImages}
            renderItem={(itemData) => <SwiperService data={itemData} />}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            paddingLeft: { xs: "0", md: "1rem" },
            marginTop: { xs: "1rem", md: "0" },
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontSize: { xs: "24px", md: "36px" } }}
          >
            {item.name}
          </Typography>
          {/* <Typography variant="body1" sx={{ marginTop: "1rem" }}>
            {serviceDescription}
          </Typography>
          <br /><br /><br /> */}
          <Typography variant="body1" sx={{ marginTop: "1rem" }}>
            <div dangerouslySetInnerHTML={{ __html: serviceDescription }} />
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: "sticky",
          bottom: "0",
          right: "0",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingBottom:
            serviceDate !== null && itemCount >= 1 && !isMobile ? "1rem" : "0",
          boxShadow: "rgba(0, 0, 2, 0.05) 0px 2px 5px 2px",
          zIndex: 9,
          background: lightMode ? "#ffffff" : "#121212",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "space-between", md: "space-between" },
            borderBottom: { xs: "1px solid #e0e0e0", md: "unset" },
            alignItems: { xs: "center", md: "center" },
            width: { xs: "100%", md: "50%" },
            marginLeft: "auto",
            paddingLeft: { xs: "0", md: "1rem" },
            paddingTop:
              selectedDate !== null && itemCount >= 1 ? "2rem" : ".5rem",
            paddingBottom:
              selectedDate !== null && itemCount >= 1 ? "2rem" : "1rem",
          }}
        >
          <Box>
            <Typography variant="body1">
              {isMobile > 778 && `Price:`}
              <span style={{ fontSize: "24px", fontWeight: 700 }}>
                £{item.price}
              </span>
            </Typography>
          </Box>
          {item?.product_size?.length ? (
            <Box sx={{ display: "flex", margin: "1rem " }}>
              {item?.product_size?.map((size, idx) => {
                return (
                  <Box
                    onClick={() => handleSizeChange(size)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: ".25rem",
                      border: "1px solid #bf0000",
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                      background:
                        itemInCart?.selectedSize === size ||
                        selectedSize === size
                          ? "#bf0000"
                          : "#ffffff",
                      transition: "background .2s",
                      color:
                        itemInCart?.selectedSize === size ||
                        selectedSize === size
                          ? "#ffffff"
                          : "#bf0000",
                      "&:hover": {
                        background: "#bf0000",
                        color: "#ffffff !important",
                        transition: "background .2s",
                      },
                      marginRight:
                        idx + 1 !== item?.product_size?.length ? "1rem" : "0",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "16px",
                      }}
                    >
                      {size}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          ) : null}

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              marginTop: { xs: "0rem", md: "0" },
              width: "100%",
              alignItems: "center",
            }}
          >
            {serviceType === "services" ? (
              <Box
                sx={{
                  marginLeft: "auto",
                  marginRight: selectedDate ? "auto" : isMobile ? "auto" : "0",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DateTimePicker", "DateTimePicker"]}
                  >
                    <DateTimePicker
                      onChange={handleDateTimeChange}
                      onClose={handleDateChange}
                      value={serviceDate}
                      minDate={dayjs()}
                      label="Select your service date"
                      sx={{ maxWidth: "250px", minWidth: "unset !important" }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            ) : null}

            {serviceDate !== null && itemCount >= 1 ? (
              <Box
                sx={{
                  marginLeft: "auto",
                  marginRight: { xs: "auto", md: "0" },
                  marginTop: { xs: "1rem", md: "0" },
                }}
              >
                <CartCounts
                  itemId={item.service_id}
                  handleDecreaseCount={handleDecreaseCount}
                  handleIncreaseCount={handleIncreaseCount}
                  handleRemoveItem={handleRemoveItem}
                  serviceType={serviceType}
                />
              </Box>
            ) : serviceType !== "services" ? (
              <Button
                variant="contained"
                sx={{ marginLeft: "auto" }}
                disabled={
                  serviceType === "services"
                    ? !selectedDate
                    : !!item?.product_size?.length && !selectedSize
                }
                color="primary"
                onClick={() => {
                  if (item?.product_size?.length) {
                    if (selectedSize) {
                      dispatch(
                        setAddToCart(
                          item,
                          selectedDate,
                          selectedSize,
                          serviceType === "products" ? "products" : "services"
                        )
                      );
                    }
                  } else {
                    dispatch(
                      setAddToCart(
                        item,
                        selectedDate,
                        selectedSize,
                        serviceType === "products" ? "products" : "services"
                      )
                    );
                  }
                }}
              >
                Add
              </Button>
            ) : null}
          </Box>
        </Box>
        {serviceDate !== null && itemCount >= 1 && isMobile ? (
          <Box
            sx={{
              position: { xs: "sticky", md: "relative" },
              background: lightMode ? "#ffffff" : "#121212",
              zIndex: 9,
              left: "0",
              marginLeft: { xs: "-0", md: "0" },
              padding: "1rem",
              bottom: { xs: "0", md: "0" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <CartCounts
              itemId={item.service_id}
              handleDecreaseCount={handleDecreaseCount}
              handleIncreaseCount={handleIncreaseCount}
              handleRemoveItem={handleRemoveItem}
              isMobile={isMobile}
            />{" "} */}
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                marginTop: { xs: "1rem", md: "2rem" },
                paddingTop: { xs: "0", md: "1rem" },
                borderTop: { xs: "none", md: "1px solid #dedede" },
              }}
            >
              <Button
                onClick={() => {
                  if (serviceType === "products") {
                    navigate("/payment/shop");
                  } else if (serviceType === "services") {
                    navigate("/payment/services");
                  }
                }}
                fullWidth
                sx={{
                  height: "40px",
                }}
                variant="contained"
              >
                Continue to Payment
              </Button>
            </Box>
          </Box>
        ) : serviceDate !== null &&
          itemCount >= 1 &&
          (!item?.product_size?.length || itemInCart?.selectedSize) ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              textAlign: "right",
              width: "100%",
              paddingTop: { xs: "0", md: "1rem" },
              paddingBottom: 0,
              borderTop: { xs: "none", md: "1px solid #dedede" },
            }}
          >
            {/* {serviceDate !== null && itemCount >= 1 && !isMobile ? (
              <CartCounts
                itemId={item.service_id}
                handleDecreaseCount={handleDecreaseCount}
                handleIncreaseCount={handleIncreaseCount}
                handleRemoveItem={handleRemoveItem}
              />
            ) : null} */}

            <Button
              sx={{ marginLeft: "1rem" }}
              variant="contained"
              onClick={() => {
                if (serviceType === "products") {
                  navigate("/payment/shop");
                } else if (serviceType === "services") {
                  navigate("/payment/services");
                }
              }}
            >
              Continue to Payment
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
