import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { openModal } from "../../redux/actions/actions";
import DocumentsRequiredModal from "../Modal/DocumentsRequiredModal/DocumentsRequiredModal";
import axiosInstance from "../../axiosInstance";
import CheckOutControlModal from "../Modal/CheckOutControlModal/CheckOutControlModal";

const OptimizedAccordion = React.memo(
  ({
    data,
    idx,
    isMobileSm,
    isMobileMd,
    allTableHeader,
    expanded,
    onChange,
  }) => {
    const [open, setOpen] = useState(false);
    const [paymentIds, setPaymentIds] = useState([]);
    const [buttonControl, setButtonControl] = useState({
      bool: false,
      type: "",
    });

    const handleOpen = (data) => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    // COE düğmesine tıklandığında çalışacak fonksiyon
    const handleDownloadPDF = (invoiceId, type) => {
      // Dinamik URL oluşturma
      let accessToken = localStorage.getItem("accessToken");
      let token = localStorage.getItem("loginUser");

      const url =
        type === "coe"
          ? `${process.env.REACT_APP_BASE_URL}invoices/${invoiceId}?invoice_type=${type}`
          : type === "mydocuments"
          ? `${process.env.REACT_APP_BASE_URL}my_documents/${invoiceId}`
          : type === "net"
          ? `${process.env.REACT_APP_BASE_URL}invoices/${invoiceId}?invoice_type=net`
          : type === "gross"
          ? `${process.env.REACT_APP_BASE_URL}invoices/${invoiceId}?invoice_type=gross`
          : "";
      const apiUrl = url;

      setButtonControl({ bool: true, type: type });
      // API isteğini gönderme
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token
            XRefreshToken: `${token}`, // XRefreshToken
            "Content-Type": "application/json", // Content-Type başlığı
          },
        })
        .then((response) => {
          const pdfUrl = response.data?.result.file;
          // PDF dosyasını indirme
          setButtonControl({ bool: false, type: "" });
          if (window.innerWidth < 768) {
            window.location.href = pdfUrl;
          } else {
            window.open(pdfUrl, "_blank");
          }
        })
        .catch((error) => {
          setButtonControl({ bool: false, type: "" });
          console.error("PDF indirilirken hata oluştu!", error);
        });
    };

    const [isChecked, setIsChecked] = useState(
      paymentIds.includes(data.payment_id)
    );

    useEffect(() => {
      if (expanded) {
        // Status değeri "fullypaid" olmayan ödemeleri filtreleyin
        const nonFullyPaidPayments = data.payments?.filter(
          (payment) => payment.status !== "fullypaid"
        );

        // Bu ödemelerden işaretlenmiş olanları bulun
        const checkedNonFullyPaidPayments = nonFullyPaidPayments.filter(
          (payment) => payment.checked
        );

        // İşaretlenmiş ödemelerin ID'lerini çıkarın
        const checkedNonFullyPaidPaymentIds = checkedNonFullyPaidPayments.map(
          (payment) => payment.payment_id
        );

        // Eğer yalnızca bir tane non-fullypaid ödeme varsa ve checked ise, bu ödemenin ID'sini ayarlayın
        if (
          nonFullyPaidPayments.length === 1 ||
          nonFullyPaidPayments[0]?.checked
        ) {
          setPaymentIds([nonFullyPaidPayments[0].payment_id]);
        } else if (checkedNonFullyPaidPaymentIds.length) {
          // Eğer birden fazla işaretlenmiş ödeme varsa, bu ID'leri ayarlayın
          setPaymentIds(checkedNonFullyPaidPaymentIds);
        } else {
          // Hiçbir şart sağlanmıyorsa, paymentIds'i boş bir diziye ayarlayın
          setPaymentIds([]);
        }
      }
    }, [expanded, data.payments]);

    useEffect(() => {
      setIsChecked(paymentIds.includes(data.payment_id));
    }, [paymentIds, data.payment_id]);

    const handleCheckboxChange = (event, paymentId) => {
      const isChecked = event.target.checked;
      setIsChecked(isChecked);
      handlePaymentIdChange(paymentId, isChecked);
    };

    const handlePaymentIdChange = (paymentId, isChecked) => {
      if (data.payments?.length !== 1) {
        setPaymentIds((prevPaymentIds) => {
          const paymentIdExists = prevPaymentIds.includes(paymentId);
          if (isChecked && !paymentIdExists) {
            return [...prevPaymentIds, paymentId];
          } else if (!isChecked && paymentIdExists) {
            return prevPaymentIds.filter((id) => id !== paymentId);
          }
          return prevPaymentIds;
        });
      }
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loaderPaymentControl, setLoaderPaymentControl] = useState(false);
    const handlePayNowClick = async () => {
      // payment/invoices/{invoice_id} yoluna yönlendirme yapın ve paymentIds'i state olarak geçin
      setLoaderPaymentControl(true);
      let accessToken = localStorage.getItem("accessToken");
      let token = localStorage.getItem("loginUser");
      try {
        // GET isteği yapılacak URL
        let url;
        let data;

        url = `${process.env.REACT_APP_BASE_URL}payment/invoice_checkout`;
        data = { payments_id: paymentIds };

        // Axios ile GET isteği yapılıyor

        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token
            XRefreshToken: `${token}`, // XRefreshToken
            "Content-Type": "application/json", // Content-Type başlığı
          },
        });
        // Yanıtın içeriğini konsola yazdır
        setLoaderPaymentControl(false);
        navigate(`/payment/invoices/${response.data.invoice_id}`, {
          state: { paymentIds: paymentIds },
        });
      } catch (error) {
        setLoaderPaymentControl(false);
        if (!error.response.success) {
          dispatch(
            openModal(
              // <CheckOutControlModal data={error.response.data} />,
              <DocumentsRequiredModal data={error.response.data} />
            )
          );
        }
        // Hata durumunda hata mesajını konsola yazdır
        console.error("Error fetching data:", error);
      }
    };

    const totalSelectedPayments = data?.payments?.reduce((acc, payment) => {
      // Eğer paymentId, seçili paymentIds listesinde varsa, o ödemenin tutarını topla
      if (paymentIds?.includes(payment?.payment_id)) {
        return acc + parseFloat(payment?.payment_amount); // Ödeme tutarını toplama ekleyin (amount yerine gerçek tutarın bulunduğu özellik adını kullanın)
      }
      return acc; // Eğer ödeme seçili değilse, toplamı değiştirme
    }, 0); // Başlangıç değeri olarak 0 kullan
    const totalPaidAmount = data?.payments?.reduce((acc, payment) => {
      // Eğer payment.selectable false ise, o ödemenin tutarını topla
      if (payment.status === "fullypaid") {
        return acc + parseFloat(payment?.payment_amount); // Ödeme tutarını toplama ekleyin
      }
      return acc; // Eğer ödeme seçilebilir ise (selectable true), toplamı değiştirme
    }, 0); // Başlangıç değeri olarak 0 kullan

    const mobileDevice = useSelector((state) => state.windowWidth.width);

    const paraFormat = (number) => {
      const formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      });
      return formatter.format(number);
    };

    const BookingDetails = ({ data }) => {
      return (
        <>
          {/* Booking detayları buraya */}
          {/* {data?.invoice_is_canceled ? (
            <Box
              sx={{
                marginLeft: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                sx={{
                  border: "2px solid #bf0000",
                  padding: ".5rem",
                  textAlign: "center",
                  borderRadius: ".25rem",
                  width: { xs: "100%", md: "fit-content" },
                  marginBottom: { xs: "1rem", md: "0" },
                  boxShadow: "0 0 3px #909090",
                  letterSpacing: "3px",
                }}
                variant="body1"
                color="primary"
              >
                Invoice is Cancelled!
              </Typography>
            </Box>
          ) : null} */}
          {data.details.length ? (
            <>
              <Typography
                color="primary"
                sx={{ fontSize: "18px", marginBottom: "1rem" }}
              >
                Booking Details
              </Typography>
              {data?.details?.map((booking, idx) => {
                return (
                  <Box key={idx}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        paddingBottom: "1rem",
                        paddingTop: "1rem",
                        borderBottom:
                          !(data?.details?.length === idx + 1) &&
                          "1px solid #efeeee",
                      }}
                    >
                      <Box>
                        <img
                          src={booking?.image}
                          alt={booking?.campus_name}
                          width={100}
                          height={100}
                        />
                      </Box>
                      <Box
                        sx={{
                          marginLeft: { xs: "0", sm: "1.75rem" },
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <Box
                          sx={{
                            marginRight: "2rem",
                            marginBottom: { xs: "1rem", lg: "0" },
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            color={"primary"}
                            sx={{ fontSize: { xs: "14px", lg: "16px" } }}
                          >
                            {booking?.campus_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",

                              marginTop: ".5rem",
                            }}
                          >
                            Standard Plus Studio
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            marginRight: "2rem",
                            marginBottom: { xs: "1rem", lg: "0" },
                          }}
                        >
                          <Typography
                            color={"primary"}
                            sx={{ fontSize: { xs: "14px", lg: "16px" } }}
                          >
                            Check-in Date
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",

                              marginTop: ".5rem",
                            }}
                          >
                            {booking?.check_in}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            marginRight: "2rem",
                            marginBottom: { xs: "1rem", lg: "0" },
                          }}
                        >
                          <Typography
                            color={"primary"}
                            sx={{ fontSize: { xs: "14px", lg: "16px" } }}
                          >
                            Check-out Date
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",

                              marginTop: ".5rem",
                            }}
                          >
                            {booking?.check_out}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            marginRight: "2rem",
                            marginBottom: { xs: "1rem", lg: "0" },
                          }}
                        >
                          <Typography
                            color={"primary"}
                            sx={{ fontSize: { xs: "14px", lg: "16px" } }}
                          >
                            Total Days
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",

                              marginTop: { xs: "0", lg: ".5rem" },
                            }}
                          >
                            {booking?.total_days}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            marginRight: "2rem",
                          }}
                        >
                          <Typography
                            color={"primary"}
                            sx={{ fontSize: { xs: "14px", lg: "16px" } }}
                          >
                            Address
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",

                              marginTop: ".5rem",
                            }}
                          >
                            {booking?.address}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </>
          ) : null}
        </>
      );
    };

    const PaymentDetails = ({ data, handleCheckboxChange, paymentIds }) => {
      return (
        <Box
          sx={{
            marginTop: mobileDevice > 778 ? "2rem" : "0",
          }}
        >
          {data?.payments?.map((payment, idx) => {
            return (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: mobileDevice > 778 ? "row" : "column",
                    flexWrap: mobileDevice > 778 ? "wrap" : "unset",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                    borderBottom: "1px solid #efeeee",
                    paddingBottom: "1rem",
                  }}
                >
                  <Box>
                    <Typography color="primary">Payment Type</Typography>
                    <Typography sx={{ marginBottom: "1rem" }}>
                      {payment?.payment_type}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="primary">Status</Typography>
                    <Typography
                      sx={{
                        marginBottom: "1rem",
                        fontWeight: "700",
                        color:
                          payment?.status === "fullypaid"
                            ? "#69C05C"
                            : payment?.status === "unpaid"
                            ? "#bf0000"
                            : payment?.status === "partiallypaid"
                            ? "#ed6c02"
                            : payment?.status === "overpaid"
                            ? "blue"
                            : "black",
                      }}
                    >
                      {payment?.status === "fullypaid"
                        ? "Fully Paid"
                        : payment?.status === "unpaid"
                        ? "Unpaid"
                        : payment?.status === "partiallypaid"
                        ? "Partially Paid"
                        : payment?.status === "depositpaid"
                        ? "Deposit Paid"
                        : payment?.status === "overpaid"
                        ? "Over Paid"
                        : payment?.status}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="primary">Paid </Typography>
                    <Typography sx={{ marginBottom: "1rem" }}>
                      {paraFormat(payment?.paid_amount)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="primary">Outstanding</Typography>
                    <Typography sx={{ marginBottom: "1rem" }}>
                      {paraFormat(payment?.outstanding_amount)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="primary">Payment</Typography>
                    <Typography sx={{ marginBottom: "1rem" }}>
                      {paraFormat(payment?.payment_amount)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="primary">Due Date</Typography>
                    <Typography sx={{ marginBottom: "1rem" }}>
                      {payment?.due_date}
                    </Typography>
                  </Box>

                  {payment.status === "fullypaid" ? (
                    <Box>
                      <Typography color="primary">Status</Typography>
                      <Typography color={"#75be5e"}>Paid</Typography>
                    </Box>
                  ) : (
                    <FormGroup key={idx}>
                      <FormControlLabel
                        disabled={!payment.selectable}
                        control={
                          <Checkbox
                            checked={
                              paymentIds.includes(payment.payment_id) ||
                              payment.checked
                            }
                            onChange={(event) =>
                              handleCheckboxChange(event, payment.payment_id)
                            }
                          />
                        }
                        label="Check to Pay"
                      />
                    </FormGroup>
                  )}
                </Box>
              </Box>
            );
          })}
          {/* <Typography color="primary" sx={{ fontSize: "18px" }}>
    Shop
  </Typography>
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: { xs: "column", lg: "row" },
    }}
  >
    <Box sx={{ display: { xs: "none", lg: "block" } }}>
      <img
        src={data?.details?.image}
        alt={data?.details?.campus_name}
        width={100}
        height={100}
      />
    </Box>
  </Box> */}
        </Box>
      );
    };

    const accordionRef = useRef();
    const [openDetails, setOpenDetails] = useState({
      isOpen: false,
      data: null,
    });
    const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

    const handleOpenDetails = (data) => {
      setOpenDetails((prev) => ({ ...prev, isOpen: !prev.isOpen, data: data }));
      if (accordionRef.current) {
        const yOffset = -100; // 100px yukarıdan boşluk bırakmak için
        const y =
          accordionRef.current.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    const DetailContent = () => {
      if (openDetails.isOpen) {
        switch (openDetails.data) {
          case "booking":
            return <BookingDetails data={data} />;
          case "payment":
            return (
              <PaymentDetails
                data={data}
                handleCheckboxChange={handleCheckboxChange}
                paymentIds={paymentIds}
              />
            );
          default:
            return null;
        }
      }

      return null;
    };

    return (
      <Accordion
        ref={accordionRef}
        key={`${data.invoice_id}-${idx}`}
        sx={{
          marginBottom: "1rem",
          borderRadius: "4px",
          paddingLeft: "2rem",
          padding: { xs: ".5rem", lg: "0 1rem" },
          position: "relative",
          overflow: "hidden",
          boxShadow: lightMode ? "0px 0px 5px #c8c8c8" : "none",
          filter: "none",
          background: loaderPaymentControl && "#f5f5f5",
          opacity: loaderPaymentControl && ".4",
          "&.Mui-expanded::after": {
            content: "''",
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            width: { xs: ".5rem", lg: "1rem" },
            height: "100%", // Açıkken yükseklik ayarı
            background: `${
              data?.status == "fullypaid" || data?.status == "overpaid"
                ? `#69C05C`
                : data?.status == "depositpaid" ||
                  data?.status == "partiallypaid"
                ? `#ed6c02`
                : `#800000`
              //data?.status === "fullypaid" ? "#2267C9" : "#FF0000"
            }`, // PaymentStatus'a göre background rengi
          },
          "&.Mui-expanded": {
            position: "relative",
          },
        }}
        expanded={expanded}
        onChange={onChange}
      >
        <AccordionSummary
          expandIcon={false}
          aria-controls={`${data?.invoice_id}bh-content`}
          id={`${data?.invoice_id}bh-header`}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {allTableHeader.map((column, idx) => {
                return (
                  <Box
                    key={column.id !== "gross_price" && column.id}
                    sx={{
                      alignItems: { xs: "flex-start", lg: "center" },
                      display: "flex",
                      flexDirection: "column",
                      width: {
                        xs: "50%",
                        sm: "33%",
                        lg: "fit-content",
                      },
                      marginBottom: {
                        xs: idx <= allTableHeader.length - 3 ? "2rem" : "0",
                        sm: idx <= allTableHeader.length - 3 ? "2rem" : "0",
                        lg: "0",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: { xs: "14px", lg: "14px" },
                        marginBottom: { xs: ".25rem", lg: "1rem" },
                      }}
                      variant="body1"
                      key={`${idx}`}
                    >
                      {column.label}
                    </Typography>

                    {column.id == "status" ? (
                      <Button
                        sx={{ minWidth: "116px" }}
                        variant="contained"
                        color={
                          (data[column.id] == "fullypaid" &&
                            !data.invoice_is_canceled) ||
                          (data[column.id] == "overpaid" &&
                            !data.invoice_is_canceled)
                            ? `success`
                            : (data[column.id] == "depositpaid" &&
                                !data.invoice_is_canceled) ||
                              (data[column.id] == "partiallypaid" &&
                                !data.invoice_is_canceled)
                            ? `warning`
                            : `error`
                        }
                        key={`${column.id}`}
                      >
                        {/^(un)/.test(data[column.id]) &&
                        !data?.invoice_is_canceled
                          ? data[column.id].charAt(0).toUpperCase() +
                            data[column.id].slice(1)
                          : data?.invoice_is_canceled
                          ? "Cancelled"
                          : data[column.id]
                              .replace(/([a-z])(paid)/, "$1 $2")
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" ")}
                      </Button>
                    ) : column.id === "student_name" ? (
                      <Typography
                        variant="body1"
                        key={`${column.id}`}
                        sx={{
                          fontSize: { xs: "14px", lg: "14px" },
                        }}
                      >
                        {data[column.id]
                          ?.split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}
                      </Typography>
                    ) : column.id === "agent_name" ? (
                      data?.created_by_agent_name ? (
                        <Typography
                          variant="body1"
                          key={`${column.id}`}
                          sx={{
                            fontSize: { xs: "14px", lg: "14px" },
                          }}
                        >
                          {data?.created_by_agent_name}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          key={`${column.id}`}
                          sx={{
                            fontSize: { xs: "14px", lg: "14px" },
                          }}
                        >
                          {/* AGENT */}
                        </Typography>
                      )
                    ) : column.id == "gross_price" ? (
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          color={"primary"}
                          sx={{ marginRight: ".5rem" }}
                          variant="body1"
                          key={`${column.id}`}
                        >
                          <RemoveRedEyeIcon />
                        </Typography>
                        <Typography
                          color={"primary"}
                          variant="body1"
                          key={`${column.id}`}
                        >
                          <DownloadIcon />
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        variant="body1"
                        key={`${column.id}`}
                        sx={{
                          fontSize: { xs: "14px", lg: "14px" },
                        }}
                      >
                        {column.id == "invoice_type"
                          ? data[column.id].label
                          : column.id == "amount" ||
                            column.id == "paid" ||
                            column.id == "outstanding"
                          ? paraFormat(data[column.id])
                          : data[column.id]}
                      </Typography>
                    )}
                  </Box>
                );
              })}

              <Button
                sx={{ maxHeight: "50px" }}
                variant="outlined"
                color={
                  data?.status == "fullypaid" || data?.status == "overpaid"
                    ? `success`
                    : data?.status == "depositpaid" ||
                      data?.status == "partiallypaid"
                    ? `warning`
                    : `error`
                  //data?.status === "fullypaid" ? "#2267C9" : "#FF0000"
                }
                //color={data?.status === "fullypaid" ? "primary" : "error"}
              >
                Payment
                <ExpandMoreIcon
                  sx={{
                    color:
                      data?.status == "fullypaid" || data?.status == "overpaid"
                        ? `#69C05C`
                        : data?.status == "depositpaid"
                        ? `#ffa726`
                        : `#bf0000`,
                    //data?.status === "fullypaid" ? "#2267C9" : "#FF0000"
                    transform:
                      expanded && expanded === `${data?.invoice_id}`
                        ? "rotate(180deg)"
                        : "0",
                  }}
                />
              </Button>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "2rem 0",
            borderTop: "1px solid #00000029",
            margin: "0 1rem",
          }}
        >
          {/* ONLY DESKTOP START */}
          {!isMobileSm ? (
            <>
              <BookingDetails data={data} />

              <PaymentDetails
                data={data}
                handleCheckboxChange={handleCheckboxChange}
                paymentIds={paymentIds}
              />
            </>
          ) : data.details.length || data.payments ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  disabled={!data.details.length}
                  onClick={() =>
                    data.details.length && handleOpenDetails("booking")
                  }
                  sx={{ marginLeft: ".5rem", marginRight: ".5rem" }}
                  variant="contained"
                >
                  Booking Details
                </Button>
                <Button
                  disabled={!data.payments.length}
                  onClick={() =>
                    data.payments.length && handleOpenDetails("payment")
                  }
                  variant="contained"
                >
                  Payment Details
                </Button>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  background: lightMode ? "#fefefe" : "#525252",
                  width: openDetails.isOpen
                    ? `${accordionRef?.current?.offsetWidth - 8}px`
                    : "0px",
                  zIndex: 1,
                  transition: "all .5s",
                  left: !openDetails.isOpen
                    ? `${accordionRef?.current?.offsetWidth}px`
                    : "8px",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      position: "sticky",
                      textAlign: "center",
                      paddingBottom: ".5rem",
                      right: "0",
                      top: "0",
                      background: lightMode ? "#fefefe" : "#525252",
                      borderBottom: "1px solid #efefef",
                    }}
                    onClick={() => handleOpenDetails()}
                  >
                    Back
                  </Typography>
                  <Box sx={{ marginTop: "2rem" }}>
                    <DetailContent />
                  </Box>
                </Box>
              </Box>
            </>
          ) : null}

          {/* ONLY DESKTOP END */}

          <Box
            sx={{
              marginTop: "2rem",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "center", sm: "flex-end", xl: "center" },
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              {/* <Button
                variant="contained"
                color="error"
                onClick={handleOpen}
                sx={{
                  width: { xs: "100px", lg: "170px", xl: "200px" },
                  fontSize: { xs: "12px", lg: "14px" },
                  padding: { xs: ".25rem", lg: "1rem" },
                  maxHeight: "40px",
                }}
              >
                Cancel Request
              </Button> */}
              <Button
                onClick={() => handleDownloadPDF(data?.invoice_id, "coe")}
                variant="contained"
                color="success"
                disabled={
                  (buttonControl.bool &&
                    (buttonControl.type === "coe" ||
                      buttonControl.type === "gross" ||
                      buttonControl.type === "net")) ||
                  buttonControl.type === "mydocuments"
                }
                sx={{
                  marginLeft: { xs: ".25rem", lg: "1rem" },
                  marginRight: { xs: ".25rem", lg: "1rem" },
                  width: { xs: "100px", lg: "170px", xl: "200px" },
                  fontSize: { xs: "12px", lg: "14px" },
                  padding: { xs: ".25rem", lg: "1rem" },
                  maxHeight: "40px",
                }}
              >
                COE
                {buttonControl.bool && buttonControl.type === "coe" ? (
                  <CircularProgress
                    sx={{
                      color: "#ffffff",
                      position: "absolute",
                      left: "calc(50% - 20px)",
                      top: "calc(50% - 20px)",
                    }}
                  />
                ) : null}
              </Button>
              {/* <Button
                onClick={() =>
                  handleDownloadPDF(data?.invoice_id, "mydocuments")
                }
                variant="contained"
                color="inherit"
                disabled={
                  (buttonControl.bool && buttonControl.type === "coe") ||
                  buttonControl.type === "mydocuments"
                }
                sx={{
                  width: { xs: "100px", lg: "170px", xl: "200px" },
                  fontSize: { xs: "12px", lg: "14px" },
                  padding: { xs: ".25rem", lg: "1rem" },
                  maxHeight: "40px",
                }}
              >
                My Documents
                {buttonControl.bool && buttonControl.type === "mydocuments" ? (
                  <CircularProgress
                    sx={{
                      color: "#ffffff",
                      position: "absolute",
                      left: "calc(50% - 20px)",
                      top: "calc(50% - 20px)",
                    }}
                  />
                ) : null}
              </Button> */}
              <Button
                onClick={() => handleDownloadPDF(data?.invoice_id, "net")}
                variant="contained"
                color="inherit"
                disabled={
                  (buttonControl.bool &&
                    (buttonControl.type === "coe" ||
                      buttonControl.type === "gross" ||
                      buttonControl.type === "net")) ||
                  buttonControl.type === "net"
                }
                sx={{
                  width: { xs: "100px", lg: "170px", xl: "200px" },
                  fontSize: { xs: "12px", lg: "14px" },
                  padding: { xs: ".25rem", lg: "1rem" },
                  maxHeight: "40px",
                }}
              >
                Net
                {buttonControl.bool && buttonControl.type === "net" ? (
                  <CircularProgress
                    sx={{
                      color: "#ffffff",
                      position: "absolute",
                      left: "calc(50% - 20px)",
                      top: "calc(50% - 20px)",
                    }}
                  />
                ) : null}
              </Button>
              <Button
                onClick={() => handleDownloadPDF(data?.invoice_id, "gross")}
                variant="contained"
                color="warning"
                disabled={
                  (buttonControl.bool &&
                    (buttonControl.type === "coe" ||
                      buttonControl.type === "gross" ||
                      buttonControl.type === "net")) ||
                  buttonControl.type === "gross"
                }
                sx={{
                  marginLeft: { xs: ".25rem", lg: "1rem" },
                  marginRight: { xs: ".25rem", lg: "1rem" },
                  width: { xs: "100px", lg: "170px", xl: "200px" },
                  fontSize: { xs: "12px", lg: "14px" },
                  padding: { xs: ".25rem", lg: "1rem" },
                  maxHeight: "40px",
                }}
              >
                Gross
                {buttonControl.bool && buttonControl.type === "gross" ? (
                  <CircularProgress
                    sx={{
                      color: "#ffffff",
                      position: "absolute",
                      left: "calc(50% - 20px)",
                      top: "calc(50% - 20px)",
                    }}
                  />
                ) : null}
              </Button>
            </Box>
            <Box
              sx={{
                marginLeft: { xs: "0", sm: "2rem", xl: "auto" },

                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                marginTop: { xs: "2rem", lg: "0" },
              }}
            >
              {paymentIds.length ? (
                <Typography
                  color="primary"
                  sx={{
                    fontSize: { xs: "18px", lg: "20px" },
                    marginRight: { xs: "0", lg: "1rem" },
                  }}
                >
                  Total Price Of Selected Payment: £
                  {totalSelectedPayments.toFixed(2)}
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  sx={{
                    fontSize: { xs: "18px", lg: "20px" },
                    marginRight: { xs: "0", lg: "1rem" },
                  }}
                >
                  Total Paid Amount: £{totalPaidAmount.toFixed(2)}
                </Typography>
              )}

              <Button
                onClick={handlePayNowClick}
                variant="contained"
                color="primary"
                disabled={
                  data?.status === "fullypaid" ||
                  data?.status === "overpaid" ||
                  !paymentIds.length ||
                  data?.invoice_is_canceled
                }
                sx={{
                  marginLeft: { xs: "0", xl: "1rem" },
                  marginTop: { xs: "1rem", lg: "0" },
                  width: {
                    xs: "100%",
                    sm: "100px",
                    lg: "170px",
                    xl: "200px",
                  },
                }}
              >
                Pay Now
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
        {loaderPaymentControl && (
          <CircularProgress
            sx={{ position: "absolute", top: "50%", left: "50%" }}
          />
        )}
      </Accordion>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.expanded === nextProps.expanded &&
      prevProps.data === nextProps.data &&
      prevProps.paymentIds === nextProps.paymentIds
    );
  }
);

export default OptimizedAccordion;
