import easytransferImg from "../../../assets/images/easytransfer.svg";
import flywireImg from "../../../assets/images/flywire.svg";

import easytransferLightImg from "../../../assets/images/easytransfer_light.svg";
import flywireLightImg from "../../../assets/images/flywire_light.svg";

export const paymentMethods = [
  {
    name: "flywire",
    img: flywireImg,
    imgLight: flywireLightImg,
  },
  // {
  //   name: "easytransfer",
  //   img: easytransferImg,
  //   imgLight: easytransferLightImg,
  // },
];
