import axios from "axios";
import { showNotification } from "./redux/actions/notificationAction";
import { store } from "./redux/store/store";
import Swal from "sweetalert2";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // İstek başarılı olduğunda çalışır
    return response;
  },
  (error) => {
    // İstek hata ile sonuçlandığında çalışır
    if (
      error.response &&
      error.response.data &&
      error.response.data.notification
    ) {
      const message = error.response.data.message || "";
      // store.dispatch(showNotification(message)); // Hata bildirimi göster
      Swal.fire({
        icon: "error",
        title: message,
        showConfirmButton: false,
        showCloseButton: true,
        
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
