import { Box, Button, Grid, Typography } from "@mui/material";
import { getTheme } from "../../styles/theme";
import GeneralModal from "../Modal/Modal";
import AddToCartModal from "./AddToCartModal/AddToCartModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import defaultCleaningImg from "../../assets/images/service_default_cleaning.jpg";
import defaultAirportImg from "../../assets/images/service_default_airport.jpg";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../actions/actions";
import {
  setAddToCart,
  setDecrementQuantity,
  setIncrementQuantity,
  setRemoveFromCart,
} from "../../redux/actions/cartActions";

export default function ServiceItems({
  item,
  selectedDate,
  setSelectedDate,
  selectedSize,
  setSelectedSize,
  serviceType,
}) {
  const [open, setOpen] = useState(false);
  const cart = useSelector((state) =>
    serviceType === "products"
      ? state.cart.products?.cart
      : state.cart.services?.cart
  );

  const itemInCart = cart?.find(
    (cartItem) => cartItem.service_id === item.service_id
  );
  const itemQuantity = itemInCart ? itemInCart.quantity : 0;
  const mobileDevice = useSelector((state) => state.windowWidth.width);
  const [defaultImage, setDefaultImage] = useState();

  const dispatch = useDispatch();

  const handleOpen = (data) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const defaultImages = [
    {
      category: "Cleaning Service",
      url: defaultCleaningImg,
    },
    {
      category: "Airport Transportation",
      url: defaultAirportImg,
    },
  ];

  useEffect(() => {
    if (item) {
      defaultImages.forEach((defaultImg) => {
        if (defaultImg.category.includes(item.category)) {
          setDefaultImage(defaultImg.url);
        }
      });
    }
  }, []);

  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  return (
    <>
      {item?.service_id !== 6 &&
      item?.service_id !== 3 &&
      item?.service_id !== 5 ? (
        <Grid item sm={6} lg={4} xl={3} sx={{ width: "100%" }}>
          <Box
            sx={{
              background: lightMode ? "#ffffff" : "#292929",
              boxShadow: "0 0px 4px #00000029",
              minHeight: "230px",
              height: "100%",
              display: "flex",
              borderRadius: "1rem",
              flexDirection: { xs: "column" },
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                background: "#ffffff",
                minWidth: "fit-content",
                maxHeight: "322px",
                height: "322px",
                minHeight: "322px",
                position: "relative",
                overflow: "hidden",
                boxShadow: "0 3px 6px #00000029",
                borderRadius: "1rem",
                margin: "1rem",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url(${
                    item.image ? item.image : defaultImage
                  })`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  filter: "blur(5px)",
                  zIndex: 0,
                },

                "&::after":
                  mobileDevice < 1000
                    ? {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, .1)",
                        zIndex: 0,
                      }
                    : {
                        display: "none",
                      },
              }}
            >
              <img
                style={{
                  maxWidth: "100%",
                  height: mobileDevice > 1000 ? "280px" : "280px",
                  width: "280px",
                  objectFit: mobileDevice > 1000 ? "cover" : "cover",
                  objectPosition: "center",
                  position: "relative",
                  zIndex: 1,
                  top: "calc(50% - 140px)",
                  borderRadius: "1rem",
                  filter: "drop-shadow(0px 0px 3px #aaaaaa)",
                  // boxShadow: "0px 0px 15px rgba(0 0 0)",
                }}
                width={185}
                src={item.image}
              />
            </Box>
            <Box
              sx={{
                padding: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography variant="h5">{item.service_name}</Typography>

              <Typography
                sx={{
                  marginTop: "1rem",
                  display: "-webkit-box" /* or inline-block */,
                  textOverflow: "ellipsis",
                  wordWrap: "break-word",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  lineHeight: "1.8em",
                  p: {
                    marginBlock: "0 !important",
                    marginBlockEnd: "0 !important",
                    marginBlockStart: "0 !important",
                  },
                }}
                variant="body2"
                color="text.secondary"
              >
                {" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.service_description,
                  }}
                />
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                {item?.price === 0 ? (
                  <Typography variant="h6">Coming Soon!</Typography>
                ) : (
                  <Typography variant="h6">£{item.price}</Typography>
                )}

                <a
                  onClick={() => item?.price !== 0 && handleOpen()}
                  style={{
                    color:
                      item?.price !== 0
                        ? lightMode
                          ? "#bf0000"
                          : "#ffffff"
                        : "grey",
                    fontSize: "12px",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                  href="#"
                >
                  More info
                </a>
              </Box>

              {itemQuantity >= 1 ? (
                <Box
                  sx={{
                    marginTop: "auto",
                    borderRadius: "1rem",
                    overflow: "hidden",
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignItems: "center",
                    width: "fit-content",
                    marginTop: { xs: "1rem", md: "auto" },
                    boxShadow:
                      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                  }}
                >
                  <Box
                    onClick={() => (
                      // dispatch(decrementQuantity(item.service_id)),
                      dispatch(
                        setDecrementQuantity(
                          item.service_id,
                          serviceType === "products" ? "products" : "services"
                        )
                      ),
                      handleOpen()
                    )}
                    sx={{
                      padding: "1rem",
                      backgroundColor: "#bf0000",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                  >
                    {itemQuantity == 1 ? (
                      <DeleteIcon
                        sx={{ color: "#ffffff" }}
                        onClick={() =>
                          // dispatch(removeFromCart(item.service_id))
                          dispatch(
                            setRemoveFromCart(
                              item.service_id,
                              serviceType === "products"
                                ? "products"
                                : "services"
                            )
                          )
                        }
                      />
                    ) : (
                      `-`
                    )}
                  </Box>
                  <Box
                    sx={{
                      marginLeft: ".5rem",
                      marginRight: ".5rem",
                      paddingLeft: ".5rem",
                      paddingRight: ".5rem",
                    }}
                  >
                    {itemQuantity}
                  </Box>
                  <Box
                    onClick={() => (
                      dispatch(
                        setIncrementQuantity(
                          item.service_id,
                          serviceType === "products" ? "products" : "services"
                        )
                      ),
                      // dispatch(incrementQuantity(item.service_id)),
                      handleOpen()
                    )}
                    sx={{
                      padding: "1rem",
                      backgroundColor: "#bf0000",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                  >
                    +
                  </Box>
                </Box>
              ) : (
                <Button
                  onClick={() =>
                    item?.price !== 0 &&
                    (!item?.product_size?.length
                      ? dispatch(
                          setAddToCart(
                            item,
                            selectedDate,
                            selectedSize,
                            serviceType === "products" ? "products" : "services"
                          )
                        )
                      : handleOpen())
                  }
                  disabled={item?.price === 0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "1rem",
                    width: "100%",
                    mt: { xs: 3, md: "auto" },
                    fontSize: "1rem",
                  }}
                  variant="contained"
                  color="primary"
                >
                  <ShoppingCartOutlinedIcon
                    sx={{ marginRight: ".5rem", color: "#ffffff" }}
                  />
                  Add
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      ) : null}
      <GeneralModal
        open={open}
        handleClose={handleClose}
        modalType={"addToCart"}
      >
        <AddToCartModal
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
          item={item}
          serviceType={serviceType}
        />
      </GeneralModal>
    </>
  );
}
