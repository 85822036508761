import { Box, Button, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import {
  filterFunc,
  setFilterItems,
  setSearchedTerm,
} from "../../../redux/actions/actions";

export default function Search() {
  const mobileDevice = useSelector((state) => state.windowWidth.width); // Filtrelenmiş ürünleri al
  const [searchTerm, setSearchTerm] = useState("");
  const selectedFilters = useSelector((state) => state.filters.selectedFilters); // Arama terimini al
  const pageType = useSelector((state) => state.filters.pageType);
  const searchedTerm = useSelector((state) => state.searchTerm.searchTerm); // Arama terimini al
  const [searchType, setSearchType] = useState({});
  const [clearSearch, setClearSearch] = useState(false);

  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const searchTermTypeRef = useRef();
  const dispatch = useDispatch();

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearh = () => {
    setSearchTerm("");

    dispatch(setSearchedTerm({}));

    setClearSearch(false);
  };

  useEffect(() => {
    if (pageType) {
      if (pageType == "commissions") {
        setSearchType({
          name: "Invoice ID",
          id: "invoice_id",
        });
      }
      if (pageType == "bookings") {
        setSearchType({
          name: "Student Name",
          id: "student_name",
        });
      }
      if (pageType == "partner_requests") {
        setSearchType({
          name: "Student Name",
          id: "student_name",
        });
      }
      if (pageType == "invoices") {
        setSearchType({
          name: "Invoice ID",
          id: "invoice_id",
        });
      }
      if (pageType == "quotes") {
        setSearchType({
          name: "Quote ID",
          id: "quote_id",
        });
      }
    }
  }, [pageType]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let searchTermType = searchTermTypeRef.current.lastChild.firstChild.id;

    dispatch(setSearchedTerm({ searchTerm, searchTermType }));
    setClearSearch(true);
  };

  useEffect(() => {
    if (Object.keys(searchedTerm).length) {
      dispatch(
        filterFunc(
          selectedFilters,
          pageType,
          searchedTerm,
          selectedBranchesString
        )
      );
    }
  }, [searchedTerm, selectedBranchesString]);
  return (
    <>
      {/* <form onSubmit={handleSubmit}>
        <TextField
          ref={searchTermTypeRef}
          id={searchType?.id}
          label={searchType?.name}
          variant="outlined"
          onChange={(e) => handleSearchTerm(e)}
        />
      </form> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: mobileDevice < 768 ? "column" : "row",
          alignItems: "stretch",
          justifyContent: "center",
          marginRight: mobileDevice < 768 ? "0" : "auto",
          order: mobileDevice < 768 ? "2" : "1",
        }}
      >
        <Box
          sx={{
            position: "relative",
            marginRight: mobileDevice < 768 ? "0" : "1rem",
          }}
        >
          <TextField
            ref={searchTermTypeRef}
            id={searchType?.id}
            label={searchType?.name}
            variant="outlined"
            onChange={(e) => {
              const value = e.target.value;
              // Sadece harfler ve boşluk karakterine izin ver
              if (searchType?.id === "student_name") {
                // if (/^[a-zA-Z\s]*$/.test(value)) {
                if (/^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]*$/.test(value)) {
                  setSearchTerm(value);
                }
              } else {
                setSearchTerm(value);
              }
            }}
            // onChange={(e) => handleSearchTerm(e)}
            value={searchTerm}
            sx={{
              width: "100%",
              ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                paddingRight: "2rem",
              },
            }}
            type={
              searchType?.id === "invoice_id" || searchType?.id === "quote_id"
                ? "number"
                : "text"
            }
          />
          {clearSearch ? (
            <CloseIcon
              sx={{
                position: "absolute",
                right: ".5rem",
                top: "calc(50% - 12px)",
                cursor: "pointer",
              }}
              onClick={handleClearSearh}
            />
          ) : null}
        </Box>

        <Button
          sx={{ marginTop: mobileDevice < 768 ? "1rem" : "0" }}
          onClick={(e) => Object.keys(searchTerm).length && handleSubmit(e)}
          variant="contained"
          color="primary"
          disabled={!Object.keys(searchTerm).length}
        >
          Search
        </Button>
      </Box>
    </>
  );
}
