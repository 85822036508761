import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { LondonData } from "./data/london";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../styles/theme";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function Map({ mapRef, locationDatas }) {
  const mapContainer = useRef(null);

  const [lng, setLng] = useState(-0.1278);
  const [lat, setLat] = useState(51.5074);
  const [zoom, setZoom] = useState(8.5);

  function computeExpandedBounds(geojson, paddingPercent = 0.2) {
    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;

    geojson.features[0].geometry.coordinates[0].forEach((coord) => {
      const [lng, lat] = coord;
      minLat = Math.min(lat, minLat);
      maxLat = Math.max(lat, maxLat);
      minLng = Math.min(lng, minLng);
      maxLng = Math.max(lng, maxLng);
    });

    const latPadding = (maxLat - minLat) * paddingPercent;
    const lngPadding = (maxLng - minLng) * paddingPercent;

    return [
      [minLng - lngPadding, minLat - latPadding],
      [maxLng + lngPadding, maxLat + latPadding],
    ];
  }

  function getPaddingForZoom(zoomLevel) {
    const maxZoom = 15;
    const minZoom = 8.5;
    const maxPadding = 0.2;
    const minPadding = 0;

    const slope = (minPadding - maxPadding) / (maxZoom - minZoom);
    return maxPadding + slope * (zoomLevel - minZoom);
  }

  const paddedBounds = computeExpandedBounds(LondonData);
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  // useEffect(() => {
  //   console.log(locationDatas, "location datas 123 321");
  // }, [locationDatas]);
  useEffect(() => {
    if (locationDatas.length && localStorage.getItem("lightMode")) {
      // if (mapRef.current) return;

      mapRef.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/outdoors-v12",
        center: [lng, lat],
        zoom: zoom,
        maxZoom: 15,
        minZoom: 8.5,
        maxBounds: paddedBounds, // Londra sınırlarını bu özellikle belirtiyoruz
      });

      mapRef.current.on("load", () => {
        const bigRectangle = [
          [-180, -85],
          [180, -85],
          [180, 85],
          [-180, 85],
          [-180, -85],
        ];

        const maskedLondonData = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                // Bu "coordinates" dizisi önce büyük dörtgeni tanımlar,
                // ardından Londra'nın sınırlarını "delik" olarak ekler.
                coordinates: [
                  bigRectangle,
                  ...LondonData.features[0].geometry.coordinates,
                ],
              },
            },
          ],
        };

        mapRef.current.addSource("masked-london", {
          type: "geojson",
          data: maskedLondonData,
        });

        mapRef.current.addLayer({
          id: "outside-london",
          type: "fill",
          source: "masked-london",
          layout: {},
          paint: {
            "fill-color": "#282828", // Gri renk
            "fill-opacity": 0.7, // Şeffaflığı ayarlama (isteğe bağlı)
          },
        });

        mapRef.current.addSource("london-boundary", {
          type: "geojson",
          data: LondonData, // Londra'nın sınırlarını içeren GeoJSON verisi
        });

        mapRef.current.addLayer({
          id: "london-boundary-line",
          type: "line",
          source: "london-boundary",
          layout: {},
          paint: {
            "line-color": "#F7F7F7", // Sınırların rengi
            "line-width": 3, // Sınırların genişliği
          },
        });

        // Add fog properties directly after the map loads
        mapRef.current.setStyle({
          ...mapRef.current.getStyle(), // Spread the current style
        });

        locationDatas.forEach((loc) => {
          // Popup oluşturun, burada <loc.name> yerine ilgili rezidansın adını yazın.
          // Popup içeriği HTML olarak belirlenebilir. Örneğin, rezidans adını içeren bir <div> veya <span> kullanılabilir.
          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<h3>${loc.campus_name}</h3>`
          ); // `loc.name` rezidansın adını temsil etmelidir.

          // Marker oluşturun
          const marker = new mapboxgl.Marker({ color: "#bf0000" })
            .setLngLat([
              parseFloat(loc.campus_coordinates.lng),
              parseFloat(loc.campus_coordinates.lat),
            ])
            // Popup'ı marker'a ekleyin
            .setPopup(popup) // Popup ekleniyor
            .addTo(mapRef.current);
        });
      });

      mapRef.current.on("zoom", () => {
        const currentZoom = mapRef.current.getZoom();
        const currentPadding = getPaddingForZoom(currentZoom);
        const newBounds = computeExpandedBounds(LondonData, currentPadding);
        mapRef.current.setMaxBounds(newBounds);
      });

      mapRef.current.on("move", () => {
        setLng(mapRef.current.getCenter().lng.toFixed(4));
        setLat(mapRef.current.getCenter().lat.toFixed(4));
        setZoom(mapRef.current.getZoom().toFixed(2));
      });

      const handleResizeAndOrientationChange = () => {
        setTimeout(() => {
          mapRef.current.resize();
        }, 200); // 200ms gecikme ile resize metodunu çağırıyoruz.
      };

      window.addEventListener("resize", handleResizeAndOrientationChange);
      window.addEventListener(
        "orientationchange",
        handleResizeAndOrientationChange
      );

      // Component temizlendiğinde (unmount) olay dinleyicilerini kaldır
      return () => {
        window.removeEventListener("resize", handleResizeAndOrientationChange);
        window.removeEventListener(
          "orientationchange",
          handleResizeAndOrientationChange
        );
      };
    }
  }, [locationDatas]);

  return (
    <Box
      ref={mapContainer}
      className="map-container"
      sx={{
        height: "500px",
        width: "100%",
        borderRadius: ".25rem",
        overflow: "hidden",
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          visibility: "hidden",
          bottom: "0",
          width: "100%",
          height: "30px",
          background: "#000000",
        },
      }}
    ></Box>
  );
}

export default Map;
