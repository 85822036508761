import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import UserGuideImg from "../../assets/images/user-guide/user-guide.jpg";
import ShopImg from "../../assets/images/user-guide/shop.png";
import InvoicesImg from "../../assets/images/user-guide/myinvoices.png";
import DashboardImg from "../../assets/images/user-guide/dashboard.png";
import MyRequestsImg from "../../assets/images/user-guide/myrequests.png";

import AdditionalImg from "../../assets/images/user-guide/additional .png";
import BookingImg from "../../assets/images/user-guide/booking.png";
import QuotesImg from "../../assets/images/user-guide/quotes.png";
import CommissionsImg from "../../assets/images/user-guide/commissions.png";

import DownloadIcon from "@mui/icons-material/Download";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import AdditionalServicesPDF from "../../assets/pdfs/user-guide/additional-services.pdf";
import QuotesPDF from "../../assets/pdfs/user-guide/quotes.pdf";
import MyRequestsPDF from "../../assets/pdfs/user-guide/my-requests.pdf";
import MyBookingsPDF from "../../assets/pdfs/user-guide/my-bookings.pdf";
import InvoicesPDF from "../../assets/pdfs/user-guide/invoices.pdf";
import CommissionsPDF from "../../assets/pdfs/user-guide/commission.pdf";
import DashboardPDF from "../../assets/pdfs/user-guide/dashboard.pdf";
import ShopPDF from "../../assets/pdfs/user-guide/shop.pdf";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function UserGuide() {
  const initGuideItems = [
    {
      id: 6,
      title: "Dashboard",
      pdfUrl: DashboardPDF,
      imgUrl: DashboardImg,
    },
    {
      id: 2,
      title: "My Requests",
      pdfUrl: MyRequestsPDF,
      imgUrl: MyRequestsImg,
    },
    {
      id: 3,
      title: "My Bookings",
      pdfUrl: MyBookingsPDF,
      imgUrl: BookingImg,
    },
    {
      id: 4,
      title: "My Invoices",
      pdfUrl: InvoicesPDF,
      imgUrl: InvoicesImg,
    },
    {
      id: 5,
      title: "My Commissions",
      pdfUrl: CommissionsPDF,
      imgUrl: CommissionsImg,
    },
    {
      id: 1,
      title: "Additional Services",
      pdfUrl: AdditionalServicesPDF,
      imgUrl: AdditionalImg,
    },

    {
      id: 7,
      title: "Shop",
      pdfUrl: ShopPDF,
      imgUrl: ShopImg,
    },
    {
      id: 8,
      title: "Quotes",
      pdfUrl: QuotesPDF,
      imgUrl: QuotesImg,
    },
  ];

  const profile = useSelector((state) => state?.profile?.profile); // Redux state'inden lightMode değerini çekin
  const [guideItems, setGuideItems] = useState();

  useEffect(() => {
    if (profile?.account_type !== "main_branch") {
      let newGuideItems = initGuideItems?.filter((item) => {
        return item?.id !== 5;
      });
      setGuideItems(newGuideItems);
    } else {
      setGuideItems(initGuideItems);
    }
  }, [profile]);
  return (
    <>
      <Typography className="title" variant="h4">
        User Guide
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["User Guide"]} />
      {!profile ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
          }}
        />
      ) : (
        <Box>
          <Typography sx={{ fontSize: "27px", marginBottom: "1rem" }}>
            Videos
          </Typography>
          <Box sx={{ borderTop: "1px solid #00000017", paddingTop: "2rem" }}>
            <Grid container spacing={3}>
              {guideItems?.map((item, idx) => {
                return (
                  <Grid
                    sx={{
                      filter: "drop-shadow(0px 3px 6px #00000029)",
                      cursor: "pointer",
                      "&:hover": {
                        ".play-arrow-icon": {
                          color: "#d9d9d9",
                          filter: "drop-shadow(5px 5px 13px #000000)",
                          transition: "all .4s",
                        },
                      },
                    }}
                    key={item.id}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                  >
                    <Link
                      to={item?.pdfUrl}
                      target="_blank"
                      style={{
                        position: "relative",
                        display: "block",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                        }}
                        src={item?.imgUrl || UserGuideImg}
                        alt="user-guide"
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "0",
                          left: "0",
                          width: "100%",
                          zIndex: "0",
                          padding: "1rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          "&::before": {
                            content: '""',
                            backgroundColor: "#ffffff",
                            opacity: ".8",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            zIndex: "-1",
                          },
                        }}
                      >
                        <Typography variant="h6" sx={{ color: "#444444" }}>
                          {item.title}
                        </Typography>
                        <Button
                          href={item?.pdfUrl}
                          target="_blank"
                          sx={{
                            fontSize: "14px",
                            color: "#ffffff",
                            borderRadius: "60px",
                            maxHeight: "30px",
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Pdf{" "}
                          <DownloadIcon
                            sx={{
                              fontSize: "14px",
                              marginLeft: ".25rem",
                              color: "#ffffff",
                            }}
                          />
                        </Button>
                      </Box>
                      {/* <PlayArrowIcon
                    className="play-arrow-icon"
                    sx={{
                      position: "absolute",
                      top: "calc(50% - 2rem)",
                      left: "calc(50% - 2rem)",
                      color: "#ffffff",
                      fontSize: "4rem",
                      cursor: "pointer",
                      transition: "all .4s",
                    }}
                  /> */}
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
}
