import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import BreadCrumbs from "../../components/Breadcrumbs";
import Basket from "../../components/Basket/Basket";
import { paymentMethods } from "./PaymentMethods/paymentMethods";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { openModal, setStudent } from "../../redux/actions/actions";
import dayjs from "dayjs";
import { SHOW_ALERT } from "../../redux/actions/actionTypes";
import {
  hideAlert,
  hideDialog,
  showAlert,
  showDialog,
} from "../../redux/actions/dialogActions";
import StyledTitleDot from "../../components/common/StyledTitleDot";
import ShoppingCartMobileModal from "../../components/Modal/ShoppingCartMobileModal";
import DocumentsRequiredModal from "../../components/Modal/DocumentsRequiredModal/DocumentsRequiredModal";
import axiosInstance from "../../axiosInstance";
import ErrorModal from "../../components/Modal/ErrorModal/ErrorModal";
import SelectStudent from "./SelectStudent/SelectStudent";

export default function Payment({ paymentType }) {
  const [loading, setLoading] = useState(true);
  const student = useSelector((state) => state.student.student);
  const [students, setStudents] = useState([]);
  const dispatch = useDispatch();
  const [selectedStudent, setSelectedStudent] = useState({});
  const [paySnack, setPaySnack] = useState(false);
  const [loadingBasket, setLoadingBasket] = useState(false);
  const [postPaymentDetails, setPostPaymentDetails] = useState(null);
  const [paymentTypeServices, setPaymentTypeServices] = useState(null);

  const params = useParams();
  const invoiceId = paymentType === "invoice" ? params.invoiceId : null;
  const bookingId = paymentType === "booking" ? params.bookingId : null;
  const cartAdditionalServices = useSelector((state) =>
    paymentType === "shop"
      ? state.cart?.products?.cart
      : state.cart?.services?.cart
  );

  const navigate = useNavigate();
  // const handleStudent = (event) => {
  //   setSelectedStudent(event.target.value);
  // };

  console.log(student, "student here");
  const handleStudent = (event, newValue) => {
    if (newValue) {
      setSelectedStudent(newValue);
    } else {
      // Kullanıcı "x" ikonuna basarak seçimi temizlediğinde burası çalışacak
      dispatch(setStudent({}));
    }
  };

  // useEffect(() => {
  //   console.log(selectedStudent, "selected student");
  //   console.log(invoiceId, "selected student");
  //   console.log(bookingId, "selected student");
  //   if (!invoiceId && !bookingId && !Object.keys(selectedStudent).length) {
  //     console.log('hereee');
  //     setPostPaymentDetails(null);
  //   }
  // }, [selectedStudent, invoiceId, bookingId]);

  const location = useLocation(); // useLocation hook'unu kullanın
  const { paymentIds, postPaymentBookingDetails, postPaymentAirportTransfer } =
    location.state || {}; // paymentIds'i state'ten çıkarın

  useEffect(() => {
    if (Object.keys(selectedStudent).length) {
      dispatch(setStudent(selectedStudent));
    } else {
      //dispatch(setStudent({}));
      // console.log(selectedStudent, "dispatch(setStudent({})");
    }
  }, [selectedStudent]);

  const getStudents = async () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/students`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
            XRefreshToken: `${token}`, // Bearer token burada ekleniyor
            "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
          },
        }
      );
      setStudents(response?.data?.student);
    } catch (error) {
      console.error("Hata oluştu!", error);
      return [];
    }
  };

  // useEffect(() => {
  //   console.log(cartAdditionalServices, "cartAdditionalServices");
  // }, [cartAdditionalServices]);

  const initialPaymentMethod = paymentMethods.find((paymentMethod) => {
    return paymentMethod.name === "flywire";
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState(initialPaymentMethod);
  const [cartItems, setCartItems] = useState([]);

  // useEffect(() => {
  //   console.log(cartItems, "cart items buada");
  // }, [cartItems]);
  const handleSetSelectedPaymentMethod = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const handleGetPaymentId = async (invoiceId) => {
    console.log("handlegetpaymentid");
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      // GET isteği yapılacak URL
      const url = `${process.env.REACT_APP_BASE_URL}payments?invoice_id=${invoiceId}`;
      // Axios ile GET isteği yapılıyor

      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
          XRefreshToken: `${token}`, // Bearer token burada ekleniyor
          "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
        },
      });
      // Yanıtın içeriğini konsola yazdır
      setCartItems(response.data.payments);
    } catch (error) {
      // Hata durumunda hata mesajını konsola yazdır
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("handleGetPaymentId 2");
    if (invoiceId !== null && !postPaymentAirportTransfer) {
      handleGetPaymentId(invoiceId);
      console.log("handleGetPaymentId 3");
    }
  }, [invoiceId]);

  useEffect(() => {
    if (bookingId !== null && postPaymentBookingDetails) {
      setPostPaymentDetails(postPaymentBookingDetails);
    }
  }, [bookingId, postPaymentBookingDetails]);

  useEffect(() => {
    if (invoiceId !== null && postPaymentAirportTransfer) {
      setPostPaymentDetails(postPaymentAirportTransfer?.payment);
    }
  }, [invoiceId, postPaymentAirportTransfer]);

  console.log(postPaymentAirportTransfer, "postPaymentAirportTransfer");
  // useEffect(() => {
  //   console.log(postPaymentAirportTransfer, "postPaymentAirportTransfer");
  // }, [postPaymentAirportTransfer]);

  // useEffect(() => {
  //   if (postPaymentBookingDetails) {
  //     console.log(postPaymentDetails, "post payment details");
  //     console.log(postPaymentBookingDetails, "postPaymentBookingDetails");
  //     console.log(bookingId, "bookingId");
  //   }
  // }, [postPaymentDetails, postPaymentBookingDetails]);

  useEffect(() => {
    // Eğer invoiceId varsa ve paymentIds undefined veya boş bir array ise
    if (
      invoiceId &&
      !postPaymentAirportTransfer &&
      (!paymentIds || paymentIds.length === 0)
    ) {
      // Kullanıcıyı bir önceki sayfaya veya belirli bir sayfaya yönlendir
      navigate("/myinvoices"); // Bir önceki sayfaya dön
      // veya belirli bir sayfaya yönlendirme için navigate('/some/path');
    } else {
      setLoading(false); // paymentIds geçerliyse yükleme durumunu false yap
    }
  }, [invoiceId, paymentIds, navigate]);

  useEffect(() => {
    if (invoiceId == null && bookingId === null) {
      setCartItems(cartAdditionalServices);

      if (students.length) {
        dispatch(setStudent({}));
      } else {
        getStudents();
      }
    }
  }, [invoiceId, bookingId, cartAdditionalServices]);

  const handlePostPayment = async (paymentIds, postPropsAdditional) => {
    console.log(postPropsAdditional, 'post props 6134');
    setPaySnack(true);
    setLoadingBasket(true);
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    try {
      // GET isteği yapılacak URL
      let url;
      let data;

      if (postPropsAdditional) {
        console.log(postPropsAdditional, "post props additional");
        url = `${process.env.REACT_APP_BASE_URL}payment/service_checkout`;
        data = postPropsAdditional;
      } else if (paymentIds) {
        url = `${process.env.REACT_APP_BASE_URL}payment/invoice_checkout`;
        data = { payments_id: paymentIds };
      }
      // Axios ile GET isteği yapılıyor

      const response = await axiosInstance.post(url, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Bearer token
          XRefreshToken: `${token}`, // XRefreshToken
          "Content-Type": "application/json", // Content-Type başlığı
        },
      });
      // Yanıtın içeriğini konsola yazdır
      if (response.message !== "documents_required") {
        setPostPaymentDetails(response.data);
        setPaySnack(false);
        setLoadingBasket(false);
      }
      // else {
      //   //ALERT BURADA YAPILACAK VE YÖNLENDİRME OLACAK
      //   alert("Please upload passport and student letter.");
      //   setSelectedStudent({});
      //   dispatch(setStudent({}));
      //   setPaySnack(false);
      //   setLoadingBasket(false);
      //   // dispatch(
      //   //   showDialog(
      //   //     "Please upload passport and student letter.",
      //   //     "error",
      //   //     5,
      //   //     (!invoiceId ? "Shop" : "Invoices")
      //   //   )
      //   // );
      // }
    } catch (error) {
      if (error.response.data.code == "document_required") {
        // dispatch(
        //   openModal(
        //     <DocumentsRequiredModal data={error.response.data} />,
        //     "documentsRequired"
        //   )
        // );
      } else {
        // dispatch(
        //   openModal(<ErrorModal data={error.response.data} />, "selectStudent")
        // );
        setPaySnack(false);
      }
      // Hata durumunda hata mesajını konsola yazdır
      console.error(error.response.data.code, "denemedir 123");

      console.error("Error fetching dataaa:", error);
    }
  };

  useEffect(() => {
    const path = location.pathname;
    if (
      path.includes("/payment/shop") ||
      path.includes("/payment/services") ||
      path.includes("/payment/airport-transfer")
    ) {
      setPaymentTypeServices(true);
    } else {
      setPaymentTypeServices(false);
    }
  }, [location]);

  useEffect(() => {
    if (invoiceId && paymentIds?.length) {
      handlePostPayment(paymentIds, null);
    }
  }, [paymentIds]);

  useEffect(() => {
    if (!Object.keys(student).length) {
      setSelectedStudent({});
    }
  }, [student]);

  // useEffect(() => {
  //   console.log(selectedStudent, "selected student ehre..");
  // }, [selectedStudent]);

  console.log(student, 'student 616161');
  useEffect(() => {
    if (!invoiceId && !bookingId) {
      if (Object.keys(student).length && cartItems.length) {
        let postPropsAdditional = {
          student_id: student.student_id,
          services: cartItems.map((item) => ({
            service_id: item.service_id,
            quantity: item.quantity,
            date: item.serviceDate
              ? // dayjs(item.serviceDate).utc().local().format("YYYY-MM-DD HH:mm")
                dayjs(item.serviceDate).format("YYYY-MM-DD HH:mm")
              : null,
            size: item.selectedSize,
          })),
        };
        handlePostPayment(null, postPropsAdditional);
      } else {
        setPostPaymentDetails(null);
      }
    }
  }, [student, cartItems]);

  // useEffect(() => {
  //   if (postPaymentDetails) {
  //     console.log(
  //       "Post payment details detected",
  //       postPaymentDetails,
  //       "Post payment details detected"
  //     );
  //   }
  // }, [postPaymentDetails]);

  // useEffect(() => {
  //   if (cartItems.length && invoiceId) {
  //     let paymentIds = cartItems.map((cartItem) => cartItem.payment_id);
  //     setPaymentIds(paymentIds);
  //   }
  // }, [cartItems]);
  console.log(postPaymentDetails, "post payment detailsss");

  function payFlywire() {
    console.log(student, "student id SOHEIL");
    // console.log(
    //   process.env.REACT_APP_PUBLIC_FLYWIRE_ENVIRONMENT,
    //   "Here is the ENVIRONMENT"
    // );
    if (!invoiceId && !bookingId && !Object.keys(student).length) {
      console.error("Hata: invoiceId gerekli");
      return;
    }

    var config = {
      env: process.env.REACT_APP_PUBLIC_FLYWIRE_ENVIRONMENT,
      nonce: postPaymentDetails.invoice_id
        ? `${postPaymentDetails?.invoice_id}--${postPaymentDetails?.flywire.include_deposit}`
        : postPaymentDetails.book_id
        ? `${postPaymentDetails?.book_id}`
        : null,
      amount:
        postPaymentDetails?.total_amount || postPaymentDetails?.total_price,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      country: "",
      recipientCode: "LTX",
      requestPayerInfo: true,
      recipientFields: {
        booking_reference: invoiceId
          ? invoiceId
          : bookingId
          ? bookingId
          : student.student_id,
        invoice_id: postPaymentDetails?.invoice_id,

        additional_information: postPaymentDetails?.flywire?.token,
      },
      paymentOptionsConfig: {
        // Only allow local card and online payments
        filters: {
          type: ["online", "credit_card"],
        },
      },

      // onCompleteCallback: (args) => {
      //   POST(API.Payment.Flywire.POST, {
      //     body: {
      //       ...args,
      //       services: cartItems.map((item) => ({
      //         service_id: item.service_id,
      //         quantity: item.quantity,
      //         date: item.serviceDate
      //           ? // dayjs(item.serviceDate).utc().local().format("YYYY-MM-DD HH:mm")
      //             dayjs(item.serviceDate).format("YYYY-MM-DD HH:mm")
      //           : null,
      //         size: item.selectedSize,
      //       })),
      //       // invoice_payments_id: paymentsId || [],
      //       // invoice_id: isInvoice ? invoiceId : invoice_id,
      //       // vat: 0,
      //     },
      //   })
      //     .then((res) => {
      //     })
      //     .catch((err) => {

      //     })
      //     .finally(() => {
      //     });
      // },

      onCompleteCallback: async (args) => {
        let services;
        let studentId;
        if (postPaymentAirportTransfer) {
          services = [postPaymentAirportTransfer.details];
          studentId = postPaymentAirportTransfer?.student_id
        } else {
          services = cartItems.map((item) => ({
            service_id: item.service_id,
            quantity: item.quantity,
            date: item.serviceDate
              ? dayjs(item.serviceDate).format("YYYY-MM-DD HH:mm")
              : null,
            size: item.selectedSize,
          }));
          studentId = student.student_id
        }
        // const services = cartItems.map((item) => ({
        //   service_id: item.service_id,
        //   quantity: item.quantity,
        //   date: item.serviceDate
        //     ? dayjs(item.serviceDate).format("YYYY-MM-DD HH:mm")
        //     : null,
        //   size: item.selectedSize,
        // }));

        const queryParams = new URLSearchParams({
          ...args,
          services: !paymentTypeServices ? null : JSON.stringify(services),
          student_id: studentId,
          // invoice_payments_id: paymentsId || [],
          // invoice_id: isInvoice ? invoiceId : invoice_id,
          // vat: 0,
        });

        let url = bookingId
          ? `${process.env.REACT_APP_BASE_URL}payment/flywire/instant_booking/callback`
          : `${process.env.REACT_APP_BASE_URL}payment/flywire/callback`;
        let accessToken = localStorage.getItem("accessToken");
        let token = localStorage.getItem("loginUser");
        try {
          const response = await axiosInstance.get(
            `${url}?${queryParams.toString()}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
                XRefreshToken: `${token}`, // Bearer token burada ekleniyor
                "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
              },
            }
          );
          console.log(response, "response here 616161");
          if (response?.data?.redirect_url) {
            window.location.href = response?.data?.redirect_url;
          }
          // navigate(`/${response?.data?.redirect_url}`);
        } catch (error) {
          console.error("API isteği sırasında hata oluştu:", error);
        }

        // POST(`${url}?${queryParams.toString()}`)
        //   .then((res) => {})
        //   .catch((err) => {})
        //   .finally(() => {});
      },

      returnUrl: `${postPaymentDetails?.flywire?.callback_url}`,
      callbackUrl: `${postPaymentDetails?.flywire?.notification_url}`,
      callbackVersion: "2",
    };

    // var config = {
    //   env: process.env.REACT_APP_PUBLIC_FLYWIRE_ENVIRONMENT,
    //   nonce: postPaymentDetails.invoice_id
    //     ? `${postPaymentDetails?.invoice_id}--${postPaymentDetails?.flywire.include_deposit}`
    //     : postPaymentDetails.book_id
    //     ? `${postPaymentDetails?.book_id}`
    //     : null,
    //   amount:
    //     postPaymentDetails?.total_amount || postPaymentDetails?.total_price,
    //   firstName: "AHMET",
    //   lastName: "USTAALIOGLU",
    //   email: "ahmet.ustaaliogu@londonist.co.uk",
    //   phone: "05316010709",
    //   address: "Eski Büyükdere Caddesi / Sarıyer/MASLAK",
    //   city: "Istanbul",
    //   country: "TR",
    //   recipientCode: "LTX",
    //   recipientFields: {
    //     booking_reference: invoiceId
    //       ? invoiceId
    //       : bookingId
    //       ? bookingId
    //       : student.student_id,
    //     invoice_id: postPaymentDetails?.invoice_id,

    //     additional_information: postPaymentDetails?.flywire?.token,
    //   },
    //   returnUrl: `${postPaymentDetails?.flywire?.callback_url}`,
    //   callbackUrl: `${postPaymentDetails?.flywire?.notification_url}`,
    //   callbackVersion: "2",
    //   // paymentOptionsConfig: {
    //   //   filters: {
    //   //     type: ["online", "credit_card"],
    //   //   },
    //   // },
    // };
    var modal = window.FlywirePayment.initiate(config);
    modal.render();
  }
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  if (loading) {
    // Burada bir yükleme göstergesi veya null döndürebilirsiniz
    return null; // veya <LoadingIndicator />
  }

  if (bookingId && !postPaymentBookingDetails) {
    navigate("/room-finder");
    return null;
  }
  return (
    <>
      <Typography className="title" variant="h4">
        Payment
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Payment"]} />
      <Grid container spacing={5} sx={{ marginTop: "1rem" }}>
        <Grid item xs={12} lg={8}>
          <Box
            sx={{
              height: "100%",
              backgroundColor: lightMode ? "#FCFCFC" : "#525252",
              filter: "drop-shadow(0px 3px 6px #00000029)",
              padding: { xs: "1rem", lg: "2rem" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!invoiceId && !bookingId ? (
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    borderBottom: "1px solid #e6e6e6",
                    paddingBottom: ".5rem",
                    marginBottom: "2rem",
                  }}
                >
                  Select Student
                </Typography>

                <Box>
                  {/* <FormControl
                    disabled={!students.length}
                    sx={{ minWidth: "200px" }}
                  >
                    <InputLabel id="select-student">Select Student</InputLabel>
                    <Select
                      labelId="select-student"
                      id="select-student"
                      value={
                        !Object.keys(student).length
                          ? "Select Student"
                          : selectedStudent
                      }
                      label="Select Student"
                      onChange={handleStudent}
                    >
                      <MenuItem
                        sx={{ display: "none" }}
                        value={"Select Student"}
                      >
                        Select Student
                      </MenuItem>
                      {students?.map((student, idx) => {
                        return (
                          <MenuItem
                            //disabled={student?.student_id !== 8107}
                            key={student?.student_id}
                            value={student}
                          >
                            {student?.student_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}
                  <SelectStudent
                    students={students}
                    paySnack={paySnack}
                    handleStudent={handleStudent}
                    selectedStudent={student}
                  />
                </Box>
              </Box>
            ) : null}

            <Typography
              sx={{
                fontSize: "24px",
                borderBottom: "1px solid #e6e6e6",
                paddingBottom: ".5rem",
                marginBottom: "2rem",
              }}
            >
              Select Your Payment Method
            </Typography>
            <Box sx={{ display: "flex" }}>
              {paymentMethods.map((paymentMethod, idx) => {
                return (
                  <>
                    <Box
                      onClick={() =>
                        handleSetSelectedPaymentMethod(paymentMethod)
                      }
                      sx={{
                        backgroundColor:
                          selectedPaymentMethod.name === paymentMethod.name
                            ? "#800000"
                            : "#ffffff",
                        width: "150px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        filter: `drop-shadow(0px 3px 6px ${
                          selectedPaymentMethod.name === paymentMethod.name
                            ? "black"
                            : "#00000029"
                        })`,
                        marginRight:
                          paymentMethods.length !== idx + 1 ? "2rem" : "0",
                      }}
                    >
                      <img
                        width={100}
                        height={100}
                        src={
                          selectedPaymentMethod.name === paymentMethod.name
                            ? paymentMethod.img
                            : paymentMethod.imgLight
                        }
                        style={{
                          objectFit: "contain",
                          objectPosition: "center",
                        }}
                      />
                    </Box>
                  </>
                );
              })}
            </Box>
            <Typography
              sx={{
                fontSize: "18px",

                marginTop: { xs: "1.5rem", lg: "3rem" },
              }}
            >
              To completing your payment with{" "}
              <strong style={{ textTransform: "capitalize" }}>
                {selectedPaymentMethod.name}{" "}
              </strong>
              payment method click to pay and completed the payment form
            </Typography>
            {paySnack && !invoiceId ? (
              <Skeleton sx={{ marginLeft: "auto" }}>
                <Button sx={{ marginLeft: "auto", marginTop: "1rem" }}>
                  Pay Now
                </Button>
              </Skeleton>
            ) : (
              <Button
                onClick={() =>
                  selectedPaymentMethod.name === "flywire" && payFlywire()
                }
                target="_blank"
                href={`${
                  selectedPaymentMethod.name === "easytransfer"
                    ? postPaymentDetails?.easytransfer?.url
                      ? postPaymentDetails?.easytransfer?.url
                      : postPaymentDetails?.easytransfer?.pay_url
                      ? postPaymentDetails?.easytransfer?.pay_url
                      : ""
                    : ""
                }`}
                variant="contained"
                color="primary"
                disabled={
                  !postPaymentDetails ||
                  postPaymentDetails?.total_amount === "0.00"
                }
                sx={{ marginLeft: "auto", marginTop: "1rem" }}
              >
                Pay Now
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          {invoiceId !== null ? (
            postPaymentDetails ? (
              postPaymentAirportTransfer ? (
                <Basket
                  invoiceId={invoiceId}
                  cartItems={postPaymentAirportTransfer}
                />
              ) : (
                <Basket invoiceId={invoiceId} cartItems={postPaymentDetails} />
              )
            ) : (
              <Basket
                invoiceId={invoiceId}
                cartItems={[]}
                loading={loadingBasket}
              />
            )
          ) : bookingId !== null ? (
            postPaymentDetails ? (
              <Basket bookingId={bookingId} cartItems={postPaymentDetails} />
            ) : (
              <Basket
                bookingId={bookingId}
                cartItems={[]}
                loading={loadingBasket}
              />
            )
          ) : (
            <Basket cartItems={cartItems} paymentType={paymentType} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
