import React, { useState, useEffect } from "react";
import { Box, Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import ProgressBar from "./DashboardMain/ProgressBar/ProgressBar";

export default function BreadCrumbs({ routs, component }) {
  // useEffect kullanılarak yapılan özel durum kontrolünü kaldırdım.
  // URL'yi dinamik olarak oluşturacak ve yönlendirme için kullanılacak bir fonksiyon ekleyeceğiz.

  const createPathFromRout = (rout) => {
    // "My Requests" için özel bir durum kontrolü
    if (rout !== "Airport Transfer") {
      if (rout === "My Requests") {
        return "/myrequests";
      } else if (rout === "Services") {
        return "/additional-services";
      } else if (rout === "Partner Requests") {
        return "/myrequests";
      }
      // Genel durum, rout'u küçük harfe çevir ve boşlukları '-' ile değiştir
      return `/${rout.toLowerCase().replace(/\s+/g, "-")}`;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: { xs: "flex-start", md: "center" },
      }}
    >
      <Breadcrumbs
        sx={{ my: 3, minWidth: "max-content" }}
        aria-label="breadcrumb"
      >
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        {routs.map((rout, idx) => {
          const pathToCurrentRout = routs
            .slice(0, idx + 1)
            .map(createPathFromRout)
            .filter(Boolean) // undefined veya boş değerleri filtrele
            .join("");

          if (rout === "Airport Transfer") {
            // Eğer rout "Airport Transfer" ise, Typography ile göster
            return (
              <Typography key={`${rout}-${idx}`} color="text.primary">
                {rout}
              </Typography>
            );
          } else {
            // Diğer durumlarda, Link olarak göster
            return idx !== routs.length - 1 ? (
              <Link
                key={`${rout}-${idx}`}
                underline="hover"
                color="inherit"
                href={pathToCurrentRout}
              >
                {rout}
              </Link>
            ) : (
              // Son eleman için de Typography kullan
              <Typography key={rout} color="text.primary">
                {rout}
              </Typography>
            );
          }
        })}

        {/* {routs.map((rout, idx) => {
          // URL'yi dinamik olarak oluşturuyoruz
          const pathToCurrentRout = routs
            .slice(0, idx + 1)
            .map(createPathFromRout)
            .join("");

          return idx !== routs.length - 1 ? (
            <Link
              key={`${rout}-${idx}`}
              underline="hover"
              color="inherit"
              href={pathToCurrentRout}
            >
              {rout}
            </Link>
          ) : (
            <Typography key={rout} color="text.primary">
              {rout}
            </Typography>
          );
        })} */}
      </Breadcrumbs>
      {component && component !== "dashboard" ? component : null}
      {/* {component && component === "dashboard" ? (
        <Box sx={{ width: { xs: "100%", md: "50%" }, marginLeft: "auto" }}>
          <ProgressBar />
        </Box>
      ) : null} */}
    </Box>
  );
}
