import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchListItems,
  openModal,
  setPageType,
} from "../../redux/actions/actions";
import { LoginContext } from "../../contexts/loginContext";
import {
  Box,
  Button,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PaginationTable from "./PaginationTable";
import BreadCrumbs from "../Breadcrumbs";
import FilterModal2 from "../Modal/FilterModal";
import {
  TableHeaders,
  TableHeadersMd,
  TableHeadersSm,
} from "../TableHeaders/TableHeaders";
import { useTheme } from "@emotion/react";
import Search from "./FilterSearchArea/Search";
import PaginationAccordion from "./PaginationAccordion";
import FilterSearchArea from "./FilterSearchArea";
import { Preview } from "@mui/icons-material";
import axios from "axios";
import Toast from "../Toast/Toast";
import StyledTitleDot from "../common/StyledTitleDot";
import Swal from "sweetalert2";

const FilterPagesGeneral = memo(function FilterPagesGeneral({
  pageName,
  filterMethods,
  buttonTypes,
}) {
  const selectedBranches = useSelector((state) => state?.branches?.branches);
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );

  const appliedFilters = useSelector((state) => state.filters.appliedFilters);
  const [toast, setToast] = useState();
  const selectedFilters = useSelector((state) => state.filters.selectedFilters); // Arama terimini al
  const searchedTerm = useSelector((state) => state.searchTerm.searchTerm); // Arama terimini al

  const [tableHeader, setTableHeader] = useState(null);
  const [allTableHeader, setAllTableHeader] = useState(null);
  const [buttons, setButtons] = useState([]);

  const dispatch = useDispatch();

  const theme = useTheme();

  const isMobileSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileMd = useMediaQuery(theme.breakpoints.down("md"));

  let newPageName;

  if (pageName === "partner_requests") {
    newPageName = "Partner Requests";
  } else if (pageName === "quotes") {
    newPageName = "Quotes";
  } else if (pageName === "invoices") {
    newPageName = "My Invoices";
  } else if (pageName === "bookings") {
    newPageName = "My Bookings";
  } else if (pageName === "commissions") {
    newPageName = "My Commissions";
  } else {
    newPageName = pageName;
  }

  useEffect(() => {
    const tableHeaderData = TableHeaders.find((header) => pageName in header)[
      pageName
    ];
    setAllTableHeader(tableHeaderData);
    if (pageName !== "invoices") {
      const tableHeaderSmData = TableHeadersSm.find(
        (header) => pageName in header
      )[pageName];

      const tableHeaderMdData = TableHeadersMd.find(
        (header) => pageName in header
      )[pageName];

      if (tableHeaderData) {
        let newTableHeader = [...tableHeaderData]; // tableHeader'ı kopyalayarak başlayın

        if (isMobileSm) {
          newTableHeader = tableHeaderSmData;
        } else if (isMobileMd) {
          newTableHeader = tableHeaderMdData;
        }

        setTableHeader(newTableHeader);
      } else {
        console.log(`${pageName} Data: data not found`);
      }
    }
  }, [isMobileSm, isMobileMd]);

  const loginControlDef = localStorage.getItem("loginUser");
  const accessToken = localStorage.getItem("accessToken");

  const getExportExcellFile = () => {
    let accessToken = localStorage.getItem("accessToken");
    let token = localStorage.getItem("loginUser");
    setToast({ type: "warning", text: "Download in process" });

    Swal.fire({
      icon: "info",
      title: "Download in process",
      showConfirmButton: false,
    });
    // Filtreleri ve arama terimlerini sorgu parametrelerine çevir
    const queryParams = new URLSearchParams();

    Object.keys(selectedFilters).forEach((key) => {
      const value = selectedFilters[key];
      // Moment nesnesini kontrol et ve formatla
      if (value && value.$d) {
        queryParams.append(key, value.format("YYYY-MM-DD"));
      } else {
        queryParams.append(key, value);
      }
    });

    if (Object.keys(searchedTerm).length) {
      queryParams.append(searchedTerm.searchTermType, searchedTerm.searchTerm);
    }

    // URL'i oluştur
    const baseURL = `${process.env.REACT_APP_BASE_URL}${pageName}?excel=true`;
    const fullURL = `${baseURL}${
      queryParams.toString() ? "&" + queryParams.toString() : ""
    }`;

    fetch(fullURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`, // Bearer token burada ekleniyor
        XRefreshToken: `${token}`, // Bearer token burada ekleniyor
        "Content-Type": "application/json", // Eğer gönderdiğiniz veri JSON formatındaysa bu başlık gerekli
      },
    })
      .then((response) => {
        // Status kontrolü yap

        if (response.status === 200) {
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );

          // Content-Disposition başlığını doğru şekilde aldığınızı doğrulayın
          if (contentDisposition) {
            // Regex kullanarak filename'i ayıklayın
            const matches = contentDisposition.match(/filename="(.+)"/);
            // matches null değilse ve bir array ise, ilk gruptaki elemanı al
            const filename = matches && matches[1];

            if (filename) {
              // Eğer filename varsa devam et
              setToast({
                type: "success",
                text: "File downloaded successfully!",
              });
              Swal.fire({
                icon: "success",
                title: "File downloaded successfully!",
                showConfirmButton: false,
                timer: 1500,
              });
              return { blob: response.blob(), filename: filename };
            } else {
              // Eğer filename alınamadıysa hata fırlat
              throw new Error(
                "Filename could not be retrieved from the Content-Disposition header."
              );
            }
          } else {
            // Eğer Content-Disposition başlığı yoksa hata fırlat
            throw new Error("Content-Disposition header is missing.");
          }
        } else {
          // Hatalı durumda bir hata fırlat
          setToast({ type: "error", text: "File couldn't downloaded!" });
          throw new Error("Dosya indirilemedi, sunucu hata döndü.");
        }
      })
      .then(({ blob, filename }) => {
        blob.then((blobData) => {
          const url = window.URL.createObjectURL(blobData);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = filename; // Sunucudan alınan dosya adını kullan
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        });
      })
      .catch((error) => {
        setToast({ type: "error", text: error.message });
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (!Object.keys(searchedTerm).length && !Object.keys(selectedFilters).length) {
      dispatch(
        fetchListItems(
          `${pageName}`,
          loginControlDef,
          accessToken,
          selectedBranchesString
        )
      );
    }
    dispatch(setPageType(pageName));

    setButtons(
      buttonTypes?.map((buttonType, idx) => {
        if (buttonType === "start_request") {
          if (pageName === "partner_requests") {
            return {
              name: "Start Request",
              url: "/room-finder",
            };
          } else {
            return {
              name: "Start Request",
              url: "/start-request",
            };
          }
        }
        if (buttonType === "export_report") {
          return {
            name: "Export Report",
            url: null,
          };
        }
        if (buttonType === "instant_booking") {
          return {
            name: "Instant Booking",
            url: "room-finder",
          };
        }
      })
    );
  }, [searchedTerm, selectedBranchesString, selectedFilters]);

  return (
    <>
      <Typography className="title" variant="h4">
        {newPageName}
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={[` ${newPageName}`]} />
      <FilterSearchArea
        filterMethods={filterMethods}
        downloadExcell={getExportExcellFile}
        buttons={buttons}
      />

      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 5,
          }}
        ></Box>
        {pageName === "invoices" ? (
          <PaginationAccordion
            tableHeader={tableHeader}
            allTableHeader={allTableHeader}
            isMobileSm={isMobileSm}
            isMobileMd={isMobileMd}
          />
        ) : (
          <PaginationTable
            tableHeader={tableHeader}
            allTableHeader={allTableHeader}
            isMobileSm={isMobileSm}
            isMobileMd={isMobileMd}
          />
        )}
      </Box>
      {/* {toast && <Toast type={toast?.type} text={toast?.text} />} */}
    </>
  );
});
export default FilterPagesGeneral;
