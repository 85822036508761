import * as React from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../components/Breadcrumbs";
import CommonForm from "./CommonForm";
import Rooms from "../../components/RoomFinder/RoomFinderSearch/Rooms";
import RequestOperations from "../../components/RoomFinder/RequestOperations/RequestOperations";
import FilterSearchArea from "../../components/MyRequests/FilterSearchArea";
import InstantBookingComponent from "../../components/RoomFinder/InstantBookingButton";
import NoDataFound from "../../components/NoDataFound";
import { setPageType } from "../../redux/actions/actions";
import { useDispatch } from "react-redux";
import StyledTitleDot from "../../components/common/StyledTitleDot";

export default function RoomFinder() {
  const initialState = {
    provider: "",
    campus: "",
    occupancy: "",
    course: "",
    currency: "",
    numberOfStudents: "",
    additionalNote: "",
    checkIn: null,
    checkOut: null,
    refreshControl: true,
  };

  const [formValues, setFormValues] = React.useState(initialState);
  const [availableRooms, setAvailableRooms] = React.useState(null);
  const [selectedRooms, setSelectedRooms] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [loaderStart, setLoaderStart] = React.useState(false);
  const [bookingData, setBookingData] = React.useState({});

  const availableRoomsRef = React.useRef(null); // Ref'i oluştur

  // React.useEffect(() => {
  //   console.log(bookingData, "booking data");
  // }, [bookingData]);

  React.useEffect(() => {
    console.log(formValues, "form values here 6161");
  }, [formValues]);

  const handleChange = (event) => {
    const { name, value, key } = event.target;
    setFormValues((prev) => ({
      ...prev,
      refreshControl: false,
      [name]: value,
    }));
  };
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setPageType("room_finder"));
  }, []);

  React.useEffect(() => {
    if (availableRoomsRef.current) {
      const yOffset = -100; // 100px yukarıdan boşluk bırakmak için
      const y =
        availableRoomsRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [availableRooms]);

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   console.log(selectedRooms, "selected rooms");
  // }, [selectedRooms]);
  //   const handleFindRooms = () => {
  //     //if (checkIn && checkOut) {
  //     const checkInDate = checkIn.format("YYYY-MM-DD");
  //     const checkOutDate = checkOut.format("YYYY-MM-DD");
  //     navigate(
  //       `/room-finder-results?checkin=${checkInDate}&checkout=${checkOutDate}`
  //     );
  //     //}
  //   };

  return (
    <>
      {loaderStart ? (
        <CircularProgress
          sx={{
            position: "fixed",
            top: "calc(50% - 20px)",
            left: "calc(50% - 20px)",
            zIndex: "999999",
          }}
        />
      ) : null}
      <Typography className="title" variant="h4">
        Room Finder
        <StyledTitleDot />
      </Typography>
      <BreadCrumbs routs={["Room Finder"]} />
      <CommonForm
        formValues={formValues}
        setFormValues={setFormValues}
        handleChange={handleChange}
        availableRooms={availableRooms}
        setAvailableRooms={setAvailableRooms}
        availableRoomsRef={availableRoomsRef}
        setLoader={setLoader}
        initialState={initialState}
        setSelectedRooms={setSelectedRooms}
      />
      <Box ref={availableRoomsRef} sx={{ paddingBottom: "6rem" }}>
        {!loader ? (
          availableRooms?.length ? (
            availableRooms?.map((availableRoom, idx) => {
              return (
                <Rooms
                  key={idx}
                  data={availableRoom}
                  formValues={formValues}
                  selectedRooms={selectedRooms}
                  setSelectedRooms={setSelectedRooms}
                  bookingData={bookingData}
                  setBookingData={setBookingData}
                />
              );
            })
          ) : !availableRooms ? // Array(10)
          //   .fill()
          //   .map((_, idx) => (
          //     <Rooms key={idx} room={false} />
          //   ))
          null : (
            <NoDataFound />
          )
        ) : (
          <CircularProgress
            sx={{ position: "relative", top: "50%", left: "50%" }}
          />
        )}
      </Box>
      {selectedRooms.length ? (
        <RequestOperations
          selectedRooms={selectedRooms}
          setSelectedRooms={setSelectedRooms}
          bookingData={bookingData}
          setBookingData={setBookingData}
          setLoaderStart={setLoaderStart}
        />
      ) : null}
    </>
  );
}
